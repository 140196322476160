$REACT_APP_API_CDN_HOST: 'https://d24vpepiy8g9c6.cloudfront.net';
.file_dropdown {
  overflow: hidden;
  visibility: hidden;
  z-index: 100;
  position: absolute;
  background-color: #FDFDFD;
  padding: 0;
  box-shadow: 0 0 0 1px rgba(14,19,24,.07), 0 2px 12px rgba(14,19,24,.2);
  color: #000;
  border-radius: 5px;
  margin: 10px;
  min-width: 200px;
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
  transform: translateY(-2em);
  opacity: 0;


  &.visible {
    visibility: visible;
    transition-delay: 0s, 0s, 0.3s; /* this removes the transition delay so the menu will be visible while the other styles transition */
    transform: translateY(0);
    opacity: 1;
  }


  .project_summary {
    margin: 10px 15px;
    .project_type {
      margin: 5px;
      font-size: 16px;
      color: rgba(0,0,0,0.4);
      font-weight: bold;
    }
    .project_desc {
      margin: 5px;
      font-size: 14px;
      color: rgba(0,0,0,0.4);
    }
  }

  hr {
    color: rgba(0,0,0,0.4);
    border-color: rgba(0,0,0,0.2);
    border-top: none;
  }

  ul {
    list-style-type: none;
    margin: 10px 0;
    padding: 0;
    a {
      text-decoration: none;
      li {
        font-size: 14px;
        padding: 10px 20px;
        color: rgba(0, 0, 0, 1);
        transition: background-color 0.2s;
        display: flex;
        justify-content: space-between;
        &:hover {
          background-color: rgba(0,0,0,0.03);
        }
      }
    }
  }
}
