$REACT_APP_API_CDN_HOST: 'https://d24vpepiy8g9c6.cloudfront.net';
.textInputControl {
  display: none;
  input {
    width: 100%;
    padding: 10px;
    min-height: 20px;
    font-size: 20px;
  }
}
@media only screen and (max-width: 1000px) {
  .textInputControl {
    display: block;
  }
}
