$REACT_APP_API_CDN_HOST: 'https://d24vpepiy8g9c6.cloudfront.net';
.design-background {
  position: absolute;
}
.page {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  pointer-events: auto;
  background-repeat: repeat;
  background-position: center;
  // overflow: hidden;
}
.page_header {
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  width: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  z-index: 100;
}

.page_margin {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  .page_margin_bg {
    pointer-events: none;
    border: 1px dashed #000;
    position: absolute;
  }
  .page_margin_fg {
    pointer-events: none;
    border: 1px solid hsla(0,0%,100%,.4);
    position: absolute;
  }
}

.page_header_item:hover {
  background-color: rgba(14,19,24,.07);
  border-radius: 3px;
  cursor: pointer;
}
.active_del:hover {
  background-color: #A9A9A9;
  border-radius: 3px;
  cursor: pointer;
}
.page_right_group {
  display: flex;
  -webkit-box-flex: 1;
  -webkit-box-pack: end;
  justify-content: flex-end;
}

.page_container {
  margin: 48px 0;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: row;
  position: relative;
  z-index: 1;

  &.image-crop {
    z-index: 2;
  }

  &.print_only {
    margin: 0;
  }
}

@media print {
  .page_container {
    margin:10px 0;
  }
  .page_header{
    display:none;
  }

  /* .page_break */
  .print_only_loaded>div {
    break-inside: avoid;
  }

  body {
      background-color: transparent;
  }

  .print_only_loaded {

      /*&>div:nth-last-child(3) .page_container.print_only {
          margin-bottom: 0!important;
      }*/

      .page {
          background-image: none!important;
      }

      .page_container.print_only {
          width: auto!important;
          height: 100%!important;
          //padding-bottom: 18px!important;
          margin-bottom: 0!important;
      }
  }

}


.page_break{
  page-break-after: always;
  page-break-inside: avoid;
}

.bleed_area {
  background-color: #ccc;
  opacity: 0.7;
  pointer-events: none;
}

.overflow_class {
  top: 0;
  // opacity: 0.5;
  overflow: hidden;
  position: absolute;
  // background-color: #ffffff;
  // border: solid 1px rgba(0,0,0,0.1);
  &.white_bg {
    background-color: #ffffff;
  }
}

.danger_zone {
  pointer-events: none;
  border-style: solid;
  border-color: rgba(255,0,0,.3);
  outline-style: solid;
  outline-color: rgba(255,0,0,.3);
  left: 0;
  top: 0;
  position: absolute;
}

.overflow_class2 {
  position: absolute;
  overflow: hidden;
  opacity: 0.3;
  &.colored {
    background-color: black;
  }
  .overflow_class3 {
    position: absolute;
    .background_element {
      overflow: visible;
    }
  }



  &.print_only {
    opacity: 1;
    border: solid 1px black;
  }
}



.hidden_container {
  margin: 0;
  left: 0;
  position: absolute;
  bottom: 0;
  pointer-events: none;
  /*border: solid 5px #ccc;*/
}
.page_title {
  width: 200px;
}
.background {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
}
.page_index {
  color: #8c6ea8
}
.page_sidebar_img {
  width: 22px;
}


.icon_comment{
  width:15px;
  height:auto;
  filter: brightness(150%);
  opacity:0.7;
  margin-top:5px;
  display:none;
}
