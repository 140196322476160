$REACT_APP_API_CDN_HOST: 'https://d24vpepiy8g9c6.cloudfront.net';
.project_preview_container {
  position: relative;
  .project_preview_header {
    width: 270px;
    background: rgba(255,255,255, 1);
    text-align: center;
    font-family: BryantMedium;
    font-size: 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .project_preview_icon_left {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 15px;
        height: 15px;
      }
    }
    .project_preview_icon_right {
      display: flex;
      align-items: center;
      cursor: default;
      img {
        width: 15px;
        height: 15px;
      }
    }
  }
  .project_preview_content {
    background: rgba(228,224,215, 1);
    width: 270px;
    height: 270px;
    overflow: hidden;
    position: relative;

    .page_container {
      margin: 0;
    }
  }
  .project_preview_pagination {
    z-index: 4;
    position: absolute;
    bottom: 0;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,0.3);
    .project_preview_bullet {
      cursor: pointer;
      margin: 3px;
      border: 1px solid white;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      display: inline-block;
      text-decoration: none;
      &.current_page {
        background-color: white;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .project_preview_container {
    display: none;
  }
}
