$REACT_APP_API_CDN_HOST: 'https://d24vpepiy8g9c6.cloudfront.net';
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

#addToChartForm, #app {
    & div, & input{
        font-family: "Open Sans Light", "BryantMedium", "Open Sans", sans-serif;
        /*font-weight:600;*/
        /*line-height: 1.6;*/
    }
}

.side_menu{
	height:100%;
  display:flex;
  align-items:center;
  justify-content:center;
}

.btn-color {
    border-radius: 20px;
    background: rgb(228, 87, 60);
    padding: 5px 11px 6px 12px;
    width: auto!important;
    margin-bottom: 12px;
    color: white;
    font-weight: 800;
}

.side_control_panel_wrapper {
  width: auto;
  height: 100%;
  /*position: absolute;*/
  display: flex;
  align-items:center;
  justify-content:center;
}

.dummy_side_control_panel_wrapper_style {
  /*background-color: #2e3357;*/
  color: #3D3D3D;
}

.element_select_menu {
	width:70px;
	height:100%;
	display:flex;
	flex-direction:column;
	background-color:#F37E5D;
	align-items: center;
	overflow-y:hidden;
  position:relative;
  z-index:5;
  overflow-x:hidden;
  transition:all 0.4s;
}

@media only screen and (max-width: 1000px) {
  .element_select_menu.collapseMenu{
    width:0;
  }
}

.MenuContainer{
	z-index:5;
	width:100%;
}

.MenuContainer.selected{
	background-color:#2e3357;
}

.MenuElementSelector{
	width:20px;
	height:20px;
	margin-top:15px;
	cursor:pointer;
	transition: transform .2s;
	z-index:5;
	margin-left:23px;
}

.MenuElementSelector:hover{
	transform: scale(1.1);
}

.MenuSelectedSelector{
	width:100%;
	height:65px;
	background-color:#E4573C;
	position:absolute;
	z-index:4;
	/*top:81px;*/
}

.menu_text{
	width:100%;
	font-size:12px;
	color:#FFF;
	text-align:center;
	/*margin-top:-5px;*/
	margin-bottom:5px;
	cursor:pointer;
  font-weight:bold;
}

.MenuContainer.selected{
	/*background-image: linear-gradient(to right, #010c00 90%, rgba(43, 61, 57));*/
}

.search_header{
	text-align:center;
    margin: 5px 0;
	width:100%;
	height:auto;
	display:flex;
	align-items: flex-start;
    justify-content: center;
    flex-direction:column;
}

.search_div{
  width: 100%;
	position:relative;
}

.clear_search{
	position:absolute;
	right:16px;
	top:8px;
	color:#333;
	cursor:pointer;
	width:22px;
	height:22px;
}

.clear_search img{
	width:100%;
	height:100%;
	opacity: 0.5;
}

.category_filter{
	width:calc(100% - 8px);
	height:40px;
	display:flex;
	align-items: center;
    justify-content: center;
    margin-top:10px;
}

.category_item{
	width:33.3%;
	height:40px;
	display:flex;
	align-items: center;
    justify-content: center;
    cursor:pointer;
    color:#777;
    padding-top:0;
    padding-bottom:0;
    border: 2px solid transparent;
    font-weight:600;
    transition: color 0.3s;
    font-size:14px;
}

.category_item:hover{
	color:#AAA;
}

.category_item.selected{
	border-bottom:2px solid #E25842;
	color:#777;
}

.elementContainer{
  width:340px;
  height:100%;
  display:flex;
  flex-direction: column;
  justify-content: top;
  z-index:3;
  background-color:#FFF;
  overflow:hidden;
  transition:all 0.4s;
  box-shadow: 0 0 1px rgba(14,19,24,.7);

}

.elementContainer.collapseMenu{
  width:0;
}
/*
@media only screen and (max-width: 1000px) {
  .elementContainer{
    width:calc(100vw - 130px);
  }
}
*/

.searchElement{
	width:calc(100% - 8px);
	height:auto;
	box-sizing : border-box;
	/*margin:auto;*/
	/*margin-top:8px;
	margin-bottom:8px;*/
	font-size:14px;
	/*font-size:1.4rem;*/
    padding: 8px 32px 8px 38px;
    border-radius:5px;
	border: 1px solid #AAA;
	background-image:url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='currentColor' d='M15.2 16.34a7.5 7.5 0 1 1 1.38-1.45l4.2 4.2a1 1 0 1 1-1.42 1.41l-4.16-4.16zm-4.7.16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
    background-position: 10px 5px;
    letter-spacing: 1px;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #AAA;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #AAA;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #AAA;
}

.menu_element_row{
	display:flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	width:100%;
	margin-bottom:15px;
}

.menu_container{
	width:auto;
	height:100%;
	min-height:70px;
	overflow-y:auto;
	text-align:center;
	display:flex;
	flex-wrap:wrap;
  overflow-x:hidden;
  padding: 10px;
}

.element_div{
	width:auto;
	height:auto;
	text-align:center;
	/*overflow:hidden;*/
	position:relative;
	padding:0;
	/*float: left;*/
	display:flex;
	align-items: center;
  	justify-content: center;
  	margin-left:3px;
  	margin-right:3px;
  	margin-bottom:6px;
}

.element_dotdiv {
	position: absolute;
	top: 2px;
	right: 0;
	width: 30px;
	border-radius: 15px;
	height: 15px;
	z-index: 1;
}

.element_dotdiv::before{
	position: absolute;
	pointer-events: none;
	right: 5px;
	top: -2px;
	width: 20px;
	background: red;
	height: 15px;
	content: "";
	background: url('./dot.png');
	background-size: cover;
}

.upload_file_name_container div {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}

.img_container{
	width:auto;
	height:auto;
	padding:3px;
	overflow:hidden;
	display:flex;
	align-items: center;
  	justify-content: center;
  	position:relative;
}

.element_cover{
	display:none;
	position:absolute;
	width:100%;
	height:100%;
	background-color:rgba(0,0,0,0.2);
	/*opacity: 0;
	transition: opacity 0.6s;*/
}

.element_cover.show{
	display:block;
	/*opacity: 1;*/
  /*background-color:#222;*/
}

.element_img{
	width:auto;
	height:auto;
	/*max-width:90px;*/
	/*max-height:90px;*/
	margin:auto;
	opacity: 1;
	transition: opacity 0.6s;

  &.pending_resize {
    opacity: 0;
  }
}

.element_img.hide{
	opacity: 0;
	transition: opacity 0.6s;
}

.element_title{
	position:absolute;
	color:#DFDFDF;
	font-size:14px;
	// background-color:rgba(0,0,0,0.7);
	font-weight:normal;
	bottom:0;
	right:0;
	width:80%;
	margin:auto;
	opacity: 0.0;
	transition: opacity 0.2s;
	padding:5px;
	border-radius:20px 0 0 0;
}
.element_div::before {
	opacity: 0.8;
}
.element_div:hover{

}

@keyframes spinner-border {
	to { transform: rotate(360deg); }
}
.spinner-border {
	width: 2rem;
	height: 2rem;
	border: .25em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	-webkit-animation: spinner-border .75s linear infinite;
	animation: spinner-border .75s linear infinite;
}

.cms-design-upload {
	@media (max-width: 375px) {
		height: 100vh;
		.container {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;

			img{
				max-height: 450px;
				max-width: 90vw;
			}
		}
	}

	.preview {
		max-width: 90vw;
		max-height: 65vh!important;
		//width: auto!important;
		width: 100%!important;

		@media (max-width: 650px) {
		}
	}

	.container {
		img {
			object-fit: contain;
		}
	}

	.file_drop_area {
		display: flex;
		flex-direction: column;
		justify-content: center;

		.file_drop_desc {
			cursor: pointer;
			height: 60%;
		}
	}

	.fa-times-circle {
		position: absolute;
		top:5px;
		right:2px;
		z-index: 20;
	}
}

.effect-design-upload {
	.filter_content {
		max-height: 90vh;
		overflow-y: auto;
	}
}

.element_div:hover .element_title{
	opacity: 1.0;
}

.delete_popup_container {
	display: none;
	position: absolute;
    right: 0;
	background-color: #fff;
	padding: 0;
	box-shadow: 0 0 0 0;
	color: #000;
	border-radius: 5px;
	z-index: 100;
	margin: 20px 0;
	width:160px;
}

.delete_style_class {
	border-radius: 4px;
	padding: 0;
	margin: 8px 0;
	background-color: #fff;
}
.delete_container {
	max-height: 223.2px;
	overflow-y: auto;
}
.upload_item_list {
	padding: 0 0 8px;
	margin: 0;
	list-style: none;
	width: 100%;
}
.list_item:hover {
	background-color: #ededed;
}
.file_name, .delete_txt {
	font-family: BryantMedium;
	font-size: 13pt;
	color: #333333;
	// word-break: break-all;
	line-height: 24px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.delete_txt {
	font-size: 12pt;
	color: #4d4d4d;
}
.upload_file_name_container {
	padding: 13px 16px 12px 20px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.selector{
	position:absolute;
	/*background-color:rgba(0,0,0,0.4);*/
	background-image: linear-gradient(to right, rgba(0,0,0,0.4) 90%, rgba(43, 61, 57, 0.4));
	width:100%;
	height:0;
	text-align:center;
	display:flex;
	align-items: center;
    justify-content: center;
    z-index:1;
}

.closeMenu{
	width:20px;
	height:auto;

  right:-18px;
  top:calc(50% - 65px);
  position:absolute;

  font-size:20px;
  color:#E6E6E6;
  background-color:transparent;
  cursor:pointer;
  z-index:3;
	transform: translate3d(0%, 0, 0);
	transition: all 600ms;
}

.closeMenu.close{
	/*transform: translate3d(-100%, 0, 0);*/
	transition: all 600ms;
}

.menu_back_arrow{
	/*background-color:#2e3357;*/
	width:100%;
	height:auto;
	padding-top:20px;
	padding-bottom:20px;
}

 /* width */
.side_menu ::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 5px;
}
.image_div:hover::-webkit-scrollbar-thumb{
	/*background-color:#AAFFAA;*/
	background: #999;
}



.lds_ring {
  display: none;/*display: inline-block;*/
  position: relative;
  width: 64px;
  height: 64px;
  /*margin-left:38%;*/
}
.lds_ring.show{
  display: inline-block;
}
.lds_ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border-radius: 50%;
  animation: lds_ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds_ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds_ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds_ring div:nth-child(3) {
  animation-delay: -0.15s;
}

.lds_ellipsis {
  display: none;
  position: relative;
  width: 64px;
  height: 8px;
}
.lds_ellipsis.show{
	display: inline-block;
}
.lds_ellipsis div {
  position: absolute;
  top: 2px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds_ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds_ellipsis1 0.6s infinite;
}
.lds_ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds_ellipsis2 0.6s infinite;
}
.lds_ellipsis div:nth-child(3) {
  left: 30px;
  animation: lds_ellipsis2 0.6s infinite;
}
.lds_ellipsis div:nth-child(4) {
  left: 50px;
  animation: lds_ellipsis3 0.6s infinite;
}
@keyframes lds_ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds_ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds_ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

@keyframes lds_ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.lds_facebook {
  display: none;
  position: relative;
  width: 100%;
  height: 64px;
}
.lds_facebook.show{
	display: inline-block;
}
.lds_facebook div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: #fff;
  animation: lds_facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds_facebook div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}
.lds_facebook div:nth-child(2) {
  left: 26px;
  animation-delay: -0.12s;
}
.lds_facebook div:nth-child(3) {
  left: 45px;
  animation-delay: 0s;
}
@keyframes lds_facebook {
  0% {
    top: 6px;
    height: 51px;
  }
  50%, 100% {
    top: 19px;
    height: 26px;
  }
}

.upload_container{
  width: calc(100% - 13px);
	text-align:left;
	margin-bottom:10px;
	padding-left:5px;
	margin-top:5px;
}
.upload_container.hide{
	display:none;
}

.upload_mine_btn{
	text-align:center;
	width:100%;
	background-color:transparent;
	padding-top:6px;
	padding-bottom:6px;
	display:inline-block;
	cursor:pointer;
	border-radius:5px;
	color:#3D3D3D;
	border:1px solid #AAA;
	transition: color 0.2s, background-color 0.2s;
	font-size:16px;
}

.tab-bar-panel {
	.upload_mine_btn{
		color:#FFF;
		//background-color:#F17F62;
		//border:1px solid #F37E5D;
	}
}

.upload_mine_btn:hover{
	background-color:#F17F62;
  	border:1px solid #F37E5D;
	color:#FFF;
}

.upload_mine{
	display:none;
}

.file_drop_area {
  background: rgba(60, 60, 60, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // padding: 20px;

  .file_drop_desc {
    border-radius: 30px;
    border: 2px dashed white;
	color: white;

    width: calc(100% - 64px - 40px);
    height: calc(100% - 40px);;
    margin-left: 84px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
/*
.text_div>div{
	float:left;
}
*/

.text_heading_section{
	text-align:left;
}

.text_heading_1{
	font-size:24px;
	padding:5px;
	cursor:pointer;
}

.text_heading_2{
	font-size:20px;
	padding:5px;
	cursor:pointer;
}

.text_heading_3{
  background-color:#FFF;
  margin: 5px;
	font-size:16px;
	padding:6px;
	/*padding-left:7px;*/
	cursor:pointer;
	text-align:center;
	color:#3D3D3D;
	border-radius:5px;
	border:1px solid #AAA;
	// width:300px;
	// margin:auto;
	transition: color 0.2s, background-color 0.2s;
}

.text_heading_3:hover{
	background-color:#F17F62;
  border:1px solid #F37E5D;
	color:#fff;
}

.text_menu, .image_menu{
	width:100%;
	display:flex;
	flex-direction: column;
	overflow-x:hidden;
  overflow-y: scroll;
  &:hover {
    overflow-x: visible;
  }
}

.image_div{
	// width:345px;
	// height:0;
	/*background-color:#AAAAFF;*/
	color:#333;
	overflow-x:hidden;
	overflow-y:scroll;
	flex-grow:1;
}

.category_element_list{
	display:flex;
	overflow-x:hidden;
  overflow-y:hidden;
	height:auto;
  justify-content: space-between;
}

.category_element_list .element_div{
	margin:0;
}

.category_element_list .element_img{
	width:auto;
	height:auto;
}

.element_list{
	display:flex;
	flex-direction:row;
	flex-wrap:wrap;
	align-items:flex-start;
	justify-content: flex-start;
	overflow-x:hidden;
}

.element_list .element_div, .element_list .img_container, .element_list .element_img{
	width:auto;
}

.layout_div .element_img{
  background-color:#FFF;
}

.element_list .element_div{
	flex-grow: 1;
	/*flex-shrink: 1;*/
	/*background-color:rgba(0,0,0,0.2);*/
	/*max-width:100px;*/
	/*width:100%;*/
}
.menu_container {
  .text_div {
    margin-top: 10px;
    width:calc(100% + 8px);
    height:0;
    /*background-color:#AAFFAA;*/
    color:#333;
    overflow-x:hidden;
    overflow-y:scroll;
    flex-grow:1;
    .empty_view {
      color: rgba(61, 61, 61, 0.7);
      font-size: 12px;
      padding: 20px 50px;

    }
    .element_list {
      justify-content: flex-start;
      align-items:flex-start;
      .element_div {
        background-color:rgba(0,0,0,0.2);
        border-radius: 4px;
        width: calc(33% - 5px);
        flex-grow: 0;
        cursor: pointer;
        transition: all 0.2s;
        &:hover {
          //filter: brightness(1.05);
        }
      }
    }
  }
}
.text_menu {
  .text_div {
    .element_list {
      .element_div {
        min-height:130px;
        border-radius: 8px;
        margin: 5px;
        width: calc(50% - 13px);
        &:hover {
          background-color:rgba(0,0,0,0.1);
        }
        /*
        .img_container {
          img {
            filter: invert(100%)
          }
        }
        */
      }
    }
  }
}

.layout_menu, .upload_menu {
  .text_div {
    margin-top: 0;
    .element_list {
      .element_div {
        min-height:130px;
        border-radius: 4px;
        margin: 5px;
        width: calc(50% - 10px);
        &:hover {
          background-color: rgba(0,0,0,0.1);
			.element_dotdiv {
				background-color: #808080;
				&:hover {
					background-color: #db9ec5;
				}
			}
        }
      }
    }
  }
}

.image_div .element_list .element_div{
	/*max-width:100px;*/
	/*background-color:rgba(0,0,0,0.2);*/
}

.element_list .img_container{
	padding:0;
}

.image_div .element_list .element_img{
	width:auto;
	height:70px;
}

.image_div {
  width: calc(100% + 8px);
  .category_div{
    width:100%;
    color:#777777;
    margin-top:15px;
    margin-bottom:15px;
    min-height:10px;
    font-size:12px;
    border-radius: 8px;
    transition: all 0.2s;
    &:hover {
      &>.right, &>.left {
        color: #3D3D3D;
      }
    }

    &>div {
      float:left;
      overflow-x:hidden;
      width: 100%;
    }
    &>.right, &>.left {
      cursor:pointer;
      transition: all 0.2s;
      width:47%;
      height:25px;
      display:flex;
      align-items:center;
    }
    .left{
      float:left;
      text-align:left;
      padding-left:3px;
      justify-content: flex-start;
      white-space: nowrap;
      width:77%;
    }
    .right{
      float:right;
      text-align:right;
      // padding-right:3%;
      display:flex;
      align-items:center;
      justify-content: flex-end;
      width:17%;
    }

    .element_div{
      width:auto;
      height:auto;
      border-radius: 4px;
      .img_container{
        width:auto;
        height:auto;
        .element_img{
          width:auto;
        }
      }
    }
  }
  .img_container{
    cursor: pointer;
    img {
      border-radius: 8px;
    }
    &:hover {
      filter: brightness(1.05);
      background-color: rgba(0,0,0,0.2);
      border-radius: 4px;
    }
  }
}


.text_div .img_container{
	width:150px;
	height:auto;
}

.text_div .element_img{
	width:100%;
	height:auto;
	margin:0;
}

/*
.category_div .element_img{
	padding:5px;
}
*/

.background_div{
	position:relative;
	// margin-bottom:5px;
}

.background_div .left{
	width:67%;
	height:25px;
	float:left;
	text-align:left;
	padding-left:3%;
	display:flex;
	align-items:center;
	justify-content: flex-start;
	font-size:12px;
}
.background_div .right{
	width:27%;
	height:25px;
	float:right;
	text-align:right;
	padding-right:3%;
	display:flex;
	align-items:center;
	justify-content: flex-end;
	flex-direction: row;
	font-size:12px;
	cursor:pointer;
}

.solid_bg_panel{
	display:flex;
	position:absolute;
	width:310px;
	height:auto;
	background-color:#FFF;
	z-index:5;
	top:30px;
	right:4px;
	align-items: flex-start;
	justify-content: center;
	flex-direction: row;
	overflow-y: hidden;
  border-radius: 5px 5px 5px 5px;
	box-shadow: inset 0 0 0 1px rgba(14,19,24,.15);
}

.solid_bg_panel.show{
	/*height:auto;*/
	/*border:1px solid #DDD;*/
}

.solid_bg_panel .container{
	width:260px;
	/*height:auto;*/
	max-height:0;
	display:flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	/*margin-top:5px;*/
	/*margin-bottom:30px;*/
	margin:0;
	/*padding-bottom:30px;*/
	transition: max-height 0.4s;
}

.solid_bg_panel.show .container{
	max-height:300px;
	transition: max-height 0.4s;
}

.solid_bg_panel .container .row{
	width:100%;
	height:40px;
	display:flex;
	align-items: center;
	justify-content: center;
}

.container .footer{
	height:30px;
}

.solid_color{
	width:32px;
	height:32px;
	box-shadow: inset 0 0 0 1px rgba(14,19,24,.15);
	border-radius:5px;
	margin:3px;
	cursor:pointer;
}

.grid{
	display:flex;
	align-items: center;
	justify-content: center;
	flex-grow:1;
}

.solid_color.a{
	background-color:#000000;
}

.solid_color.b{
	background-color:#545454;
}

.solid_color.c{
	background-color:#737373;
}

.solid_color.d{
	background-color:#a6a6a6;
}

.solid_color.e{
	background-color:#d9d9d9;
}

.solid_color.f{
	background-color:#ffffff;
}

.solid_color.a2{
	background-color:#ff5757;
}

.solid_color.b2{
	background-color:#ff66c4;
}

.solid_color.c2{
	background-color:#cb6ce6;
}

.solid_color.d2{
	background-color:#8c52ff;
}

.solid_color.e2{
	background-color:#5271ff;
}

.solid_color.f2{
	background-color:#38b6ff;
}

.solid_color.a3{
	background-color:#5ce1e6;
}

.solid_color.b3{
	background-color:#7ed957;
}

.solid_color.c3{
	background-color:#c9e265;
}

.solid_color.d3{
	background-color:#ffde59;
}

.solid_color.e3{
	background-color:#ffbd59;
}

.solid_color.f3{
	background-color:#ff914d;
}

.all_more{
	display:flex;
	align-items:center;
	justify-content: flex-end;
}

.color_title_default{
	width:100%;
	height:auto;
	color:#c2c3c5;
	font-size:10px;
	font-weight:500;
	display: flex;
	flex-basis: 100%;
	align-items: center;
    margin: 18px 0 8px;
}
.color_title_default::before{
	content: "";
	background: transparent;
	width:0;
	height: 1px;
	font-size: 0;
	line-height: 0;
	margin: 0 4px;
}
.color_title_default::after {
	content: "";
	flex-grow: 1;
	background: #c2c3c5;
	height: 1px;
	font-size: 0;
	line-height: 0;
	margin: 0 8px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.add_icon{
	width:20px;
	height:20px;
	opacity:1;
	transition: opacity 0.2s;
}

.add_icon_hover{
	width:20px;
	height:20px;
	opacity:0;
	transition: opacity 0.2s;
}
/*
.text_heading_3:hover .add_icon{
	background-image:url('./add.png');
}
*/
.add_text_div{
	display:flex;
	align-items: center;
	justify-content: center;
}

.add_icon_container{
	width:20px;
	height:20px;
	position:relative;
	margin-right:5px;
}

.add_icon_container img{
	position:absolute;
	top:0;
	left:0;
}

.text_heading_3:hover .add_icon{
	opacity:0;
}

.text_heading_3:hover .add_icon_hover{
	opacity:1;
}
.brand_images {
	margin: 10px 0;
}
.brand_images_heading {
	font-family: BryantMedium;
	font-size: 13pt;
	color: #ffffff;
	margin-bottom: 15px;
}

/*
.layout_div{

  height: 0;
  overflow-y: scroll;
  flex-grow: 1;

  .element_list{
    column-count: 2;
    column-gap: 1em;
    display:block;
  }

  .element_div{
    background-color: #eee;
    display: inline-block;
    margin: 0 0 1em;
    flex-grow: 0;
    min-width:100%;
  }

  .element_img{
    width:100%;
    height:auto;
  }

}
*/

.backgroud_div{
  /*
  .element_list{
    column-count: 3;
    column-gap: 1em;
    display:block;
  }
  */
  .element_list{
    display:flex;
    align-items:center;
    justify-content:flex-start;
    flex-direction:row;
  }

  .img_container{
    width:auto;
    height:auto;
  }

  .element_div{
    /*background-color: #eee;*/
    display: flex;
    flex-grow: 0;
    width: calc(100% / 3 - 6px);
  }

  .element_img{
    width:100%;
    height:95px;
    object-fit:cover;
  }

}

.category_show {
	display: none;
}

.category_hide {
	display: block;
}

.my_favourite{
  position:absolute;
  bottom:0;
  right:0;
  cursor:pointer;
  background-color:rgba(0,0,0,0.5);
  border-radius:100%;
  padding:3px;
  display:flex;
  align-items:center;
  justify-content:center;
  margin-bottom:5px;
  margin-right:5px;
  transform: translate(0px, 50px);
  transition: all 0.2s;
}

.img_container:hover .my_favourite{
  transform: translate(0px, 0px);
}

.sideMenuFilter{
  display:flex;
  align-items:center;
  justify-content:flex-start;
  padding-top:8px;
  padding-bottom:4px;
  padding-left:12px;
}

.sideMenuFilter img{
  width:20px;
  height:20px;
  padding:0;
  margin-right:10px;
  cursor:pointer;
}

.sideMenuFilter span{
  cursor:pointer;
  font-size:14px;
}

.side_menu_row{
  display:flex;
  align-items:center;
  justify-content:center;
  flex-direction:column;
}

.layout_div{

  width:100%;
  padding:0;
  margin:10px 0;

  .img_container{
    width:100%;
    height:auto;
  }

  .element_div{
    width:48%;
  }

  .element_img{
    width:100%;
    height:auto;
  }

}

.layout_container .side-control-panel__back-btn {
  text-align: left;
  display: block;
  color: white;
  left: 0;
  text-decoration: none;
  margin: 10px;
}

.myBrand{
  margin-top:10px;
  border-bottom:2px solid rgba(138, 122, 182,0.7);
  padding-bottom:10px;
}

.myBrandTitle{
  color:#FFF;
}
.used_color_list {
    width: 100%;
    display: flex;
    padding: 15px 0 0;
    border-bottom: none;
}
.selected_color_list {
    display: flex;
}
.bg_color_picker_btn {
    margin-right: 15px;
    width: 33px;
    height: 33px;
}
.bg_color_title {
    font-size: 16pt;
}

@media only screen and (max-width: 767px) {
  .element_list .element_div{
    flex-grow: 0;
  }
}
