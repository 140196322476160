.position_picker {
    cursor: pointer;
}


.toolbar_panel {
    margin-left: -10%;
}
.align_popup_container {
    border-radius: 4px;
    padding: 8px;
    margin: 8px 0;
    background-color: #fff;
}
.align_popup_content {
    direction: ltr;
    display: flex;
}
.container_toolbar {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
}

.container_btn_toolbar1 {
    background: transparent;
    border: 0;
    border-radius: 0;
    padding: 0 3px;
    margin: 0;
    height: 32px;
    min-width: 0;
    color: rgba(14,19,24,.3);
    transition: background-color .3s ease,color .2s ease-out;
    display: inline-flex;
    letter-spacing: .01rem;
    line-height: 1.4;
    max-width: 100%;
    outline: 0;
    vertical-align: middle;
    -webkit-box-align: center;
    align-items: center;
    font-family: Open Sans,sans-serif;
    font-weight: 400;
}
.is_active_position_btn {
    border: 1px solid transparent;
    border-radius: 3px;
    cursor: pointer;
    color: #0e1318;
}
.container_btn_toolbar.active {
    background-color: #666;
    color: white;
}

