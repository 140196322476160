.property_panel{
    margin: 0 5px;
    padding: 0 5px;
    position: relative;
}
.property_btn {
    cursor: pointer;
    padding: 0 3px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    height: 32px;
    min-width: 0;
    color: #0e1318;
    transition: background-color .3s ease,color .2s ease-out;
    margin: 0;
    -webkit-box-pack: center;
    outline: none;
    justify-content: center;
}
.property_title_box {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    height: 100%;
    line-height: 1.6;
    padding: 0;
}
.property_btn:hover {
    background-color: rgba(14,19,24,.07);
}
.property_label{
    padding: 0;
    border: 0;
    border-radius: 0;
    background: transparent;
    outline: none;
    font-size: 15px;
    white-space: nowrap;
    text-align: left;
    width: 100%;
}
.label_width {
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    border-right: 1px #e4e4e4 solid;
}
.label_width_fsize {
    width: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.popup_contianer {
    display: none;
    position: absolute;
    background-color: #FDFDFD;
    padding: 0;
    box-shadow: 0 0 0 1px rgba(14,19,24,.07), 0 2px 12px rgba(14,19,24,.2);
    color: #000;
    border-radius: 5px;
    z-index: 100;
    margin: 10px;
}
@media only screen and (max-width: 760px) {
    .popup_contianer/*.textFontFamily*/ {
        bottom: -508px;
    }
    /*.popup_contianer.textFontSize {
        bottom: -508px;
    }*/
}

.show_popup_contianer {
    display: block;
}
.s_contianer {
    border-radius: 4px;
    padding: 0;
    /*margin: 8px 0;*/
    background-color: #fff;
}
.rect_continer {
    padding: 8px;
}
.picker_list {
    padding: 0;
    margin: 0;
}
.picker-panel {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 52vh;
}
.picker-panel ul {
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.list_style {
    list-style: none;
}
.active_btn {
    background-color: rgba(14,19,24,.07);
    border-radius: 4px;
}
.active_btn .label_width_fsize {
    background-color: rgb(255 255 255);
    border-bottom: 1px solid #d3bbbb;
}
.property_container {
    display: block;
    border-radius: 3px;
    border: 1px #e4e4e4 solid;
}
.img_class {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 30px;
    height: 30px;
}
.choose_font_type ul {
    padding: 0;
}
.choose_font_type ul li {
    padding: 15px 20px;
    cursor: pointer;
}
.popup_contianer_align  {
    position: absolute;
    background-color: #FDFDFD;
    padding: 0;
    color: #000;
    border-radius: 5px;
    z-index: 100;
    margin: 4px 0;
}
.position_right_container {
    right: 5px;
}
.modal-content {
    background-color: #ffffff;
}
.font_type {
    cursor: pointer;
}
.font_type:hover, .choose_font_type ul li:hover {
    color: #db9ec5;
}
.font_type.toggled::before {
    opacity: .99;
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.font_type::before {
    position: absolute;
    pointer-events: none;
    left: 38%;
    width: 20px;
    background: red;
    height: 20px;
    content: "";
    background: url(./arrow.png);
    background-size: cover;
    opacity: .6;
    -webkit-transition: all 500ms ease-out;
    -ms-transition: all 500ms ease-in;
    -o-transition: all 500ms ease-in;
    -moz-transition: all 500ms ease-in;
    transition: all 500ms ease-out;
    top: 19px;
}
.font_type_container {
    padding: 5px 15px;
    cursor: pointer;
}

@media only screen and (max-width: 1000px) {
    .property_label{
        font-size:10pt;
    }

    .picker_option{
        width:200px;
    }

    .font_type::before {
        left: 80%;
    }
}
