.align_picker {
    padding: 0 4px;
    border-left: 1px solid rgba(14,19,24,.15);
    border-right: 1px solid rgba(14,19,24,.15);
}
.show_popup_contianer {
    display: block;
}
.popup_contianer_align {
    display: none;
    position: absolute;
    background-color: #FDFDFD;
    padding: 0;
    box-shadow: 0 0 0 1px rgba(14,19,24,.07), 0 2px 12px rgba(14,19,24,.2);
    color: #000;
    border-radius: 5px;
    z-index: 100;
    margin: 4px 0;
}
.s_contianer_align {
    border-radius: 4px;
    padding: 0;
    margin: 8px;
    background-color: #fff;
}
