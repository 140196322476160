$REACT_APP_API_CDN_HOST: 'https://d24vpepiy8g9c6.cloudfront.net';
.share_permission_container{
	padding:10px;
	display:flex;
	align-items:center;
	justify-content:center;
	/*border-top:1px solid #CCC;*/
	border-bottom:1px solid #CCC;
	/*margin-top:-1px;*/
}

.share_permission_photo{
	height:100%;
	flex-grow:1;
	/*background-color:#FFAAAA;*/
	display:flex;
	align-items:center;
	justify-content:center;
}
.share_permission_name{
	flex-grow:3;
	/*background-color:#AAFFAA;*/
	padding:5px;
	font-size:14px;
}
.share_permission_permission{
	flex-grow:1;
	/*background-color:#AAAAFF;*/
	display:flex;
	align-items:center;
	justify-content:center;
	padding:5px;
}

.photo_img{
	width:50px;
	height:50px;
}

.individual_share_div{
	width:100%;
	height:auto;
	/*background-color:#AAFFAA;*/
	padding-top:10px;
	padding-bottom:10px;
}

.individual_form_group{
	width:100%;
	height:auto;
	/*background-color:#DDD;*/
	/*margin-left:5%;*/
	padding-top:5px;
	padding-bottom:5px;
	/*padding:10px;*/
	color:#333;
	display:flex;
}

.individual_form_group .left{
	margin-left:5%;
	width:55%;
	display:flex;
	align-items: center;
	justify-content:center;
}
.individual_form_group .right{
	width:35%;
	display:flex;
	align-items: center;
	justify-content:center;
	margin-left:-5px;
	margin-right:3px;
}

.individual_email{
	width:100%;
	padding-top:0;
	padding-bottom:0;
	padding-left:5px;
	font-size:14px;
	height:30px;
	border-top:1px solid #dfdfdf;
	border-left:1px solid #dfdfdf;
	border-bottom:1px solid #dfdfdf;
	border-right:none;
	border-radius:5px;
}

.individual_permission_select{
	width:100%;
	height:32px;
	-webkit-appearance: none;
	-moz-appearance: none;
    background: transparent url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") no-repeat;
    background-position-x: 100%;
	background-position-y: 5px;
	border-top: 1px solid #dfdfdf;
	border-right: 1px solid #dfdfdf;
	border-bottom: 1px solid #dfdfdf;
	border-left:none;
	border-radius:5px;
	padding-right: 2rem;
	padding-left: 40px;

}
.individual_permission_select:focus, .individual_permission_select:active{
	text-decoration: none;
	outline: none;
}

.invitation_div{
	display:flex;
	align-items:center;
	justify-content:flex-start;
	/*height:40px;*/
	/*padding-left:5%;*/
	margin-left:20px;

}

.invitation_btn{
	color:#FFF;
	background-color:#563178;
	border-radius:5px;
	width:170px;
	/*height:30px;*/
	cursor:pointer;
	border:1px solid #563178;
	/*box-shadow: 3px 3px 5px rgba(100,100,100,0.8);*/
	transition: all 0.2s;
	font-weight:bold;
	padding:8px 20px;
	letter-spacing: 1px;
	font-size:12px;
}

.invitation_btn.hover{
	background-color:#FFF;
	color:#563178;
}

.team_share_select{
	height:32px;

	-webkit-appearance: none;
	-moz-appearance: none;
    background: transparent url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") no-repeat;
    background-position-x: 100%;
	background-position-y: 5px;
	/*border: 1px solid #dfdfdf;*/
	border: 1px solid transparent;
	border-radius: 2px;
	padding-right: 1.5rem;

}

.team_share_div{
	max-height:360px;
	/*max-height:100px;*/
	overflow-y:auto;
}

.share_permission_header{
	height:60px;
}

.share_pop_up_header_div{
	padding-top:4px;
}

.share_pop_up_header_div span{
	font-size:18px;
}

.share_pop_up_header_div p{
	font-size:12px;
	margin:0;
}

.share_to_individual{
	font-size:14px;
	color:#555;
	padding: 0 20px;
	position:relative;
}

.share_to_individual:after{
	content:'';
	position:absolute;
	background-color:#CCC;
	height:1px;
	width:240px;
	top:6px;
	margin-left:7px;
}

.share_to_team_desc{
	font-size:13px;
	height:auto;
}
