.btn_text_container {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 0;
    cursor: pointer;
    padding: 0 8px;
    box-sizing: border-box;
    color: #0e1318;
    height: 40px;
    outline: none;
}
.btn_text_width {
    width: 100%
}
.disable_btn {
    cursor: default;
    color: rgba(14,19,24,.3);
}

.header_border:hover {
    border-radius: 4px;
    background-color: rgba(14,19,24,.15);
}
.active_btn {
    background-color: rgba(14,19,24,.07);
    border-radius: 4px;
}