$REACT_APP_API_CDN_HOST: 'https://d24vpepiy8g9c6.cloudfront.net';
.icon_button {
  background-color: hsla(0,0%,100%,.95);
  border: none;
  transition: color 0.2s, background-color 0.2s, opacity 0.2s;
  border-radius: 4px;
  padding: 6px;
  display: inline-flex;
  letter-spacing: .01rem;
  line-height: 1.4;
  max-width: 100%;

  &:hover {
    background-color: #FFFFFF;
  }
  &.inactive {
    background-color: hsla(0,0%,100%,.8);
    opacity: 1;
    cursor: default;
    &:hover {
      background-color: hsla(0,0%,100%,.95);
    }
    .text_box {
      opacity: 0.5;
    }
  }

  &.img_only {
    padding: 4px 8px;
    .text_container {
      padding: 0;
    }
    .icon {
      padding-right: 0;
      img {
        width: 24px;
      }
    }
  }

  &.transparent_button {
    background-color: rgba(0,0,0,0);
    &:hover {
      background-color: rgba(0,0,0,0.1);
    };
    &.inactive {
      cursor: default;
      &:hover {
        background-color: rgba(0,0,0,0);
      };
    }
    .text_box {
      color: #919191;
    }
  }

  .text_container {
    padding: 0 8px;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
  }

  .btn_text_container {
      height: 32px;
      min-width: 0;
      color: #0e1318;
      transition: background-color .3s ease,color .2s ease-out;
      -webkit-box-pack: center;
      justify-content: center;
  }

  .text_box {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    color: #8c6ea8;
    font-size: 11pt;
    max-width: 100%;
    overflow: hidden;
    transition: color 0.2s, background-color 0.2s, opacity 0.2s;
  }

  .icon {
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 24px;
    height: 24px;
    padding-right:8px;
    img {
      width: 20px;
    }
  }
}


.btn_disabled{
  opacity:0.4;
}

@media only screen and (max-width: 1000px) {
  .img_only{
    padding:0 !important;
  }
  .icon{
    margin:0 5px !important;
  }
}
.text_width {
  width: 30px;
}
