$REACT_APP_API_CDN_HOST: 'https://d24vpepiy8g9c6.cloudfront.net';
/*fonts */
@font-face { font-family: 'AcuminPro Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AcuminPro-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/AcuminPro-Bold.ttf') format('truetype'); }
@font-face { font-family: 'AcuminPro Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AcuminPro-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/AcuminPro-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'AcuminPro Bold'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/AcuminPro-BoldItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/AcuminPro-BoldItalic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'AcuminPro Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AcuminPro-Regular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/AcuminPro-Regular.ttf') format('truetype'); }
@font-face { font-family: 'AcuminPro Regular'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/AcuminPro-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/AcuminPro-Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Arial'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Arial.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Arial.ttf') format('truetype'); }
@font-face { font-family: 'Arial'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Arial Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Arial Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Arial'; src: url($REACT_APP_API_CDN_HOST + '/fonts/arial-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/arial-Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Arial'; src: url($REACT_APP_API_CDN_HOST + '/fonts/ArialBoldItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/ArialBoldItalic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'Arial Black'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Arial-Black.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Arial Black.ttf') format('truetype'); }
@font-face { font-family: 'Arial Black'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Arial-BlackItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Arial-Black-Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Arial Black'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Arial Bold Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Arial Bold Italic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'Apple Chancery'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Apple-Chancery.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Apple-Chancery.ttf') format('truetype'); }
@font-face { font-family: 'Apples Script Demo'; src: url($REACT_APP_API_CDN_HOST + '/fonts/ApplesScriptDemo.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/ApplesScriptDemo.ttf') format('truetype'); }
@font-face { font-family: 'Apple SDGothicNeoSB00'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AppleSDGothicNeoSB00.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/AppleSDGothicNeoSB00.ttf') format('truetype'); }
@font-face { font-family: 'Apple SDGothicNeoR00'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AppleSDGothicNeoR00.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/AppleSDGothicNeoR00.ttf') format('truetype'); }
@font-face { font-family: 'Avenir Book'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Avenir-Book.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Avenir-Book.ttf') format('truetype'); }
@font-face { font-family: 'Avenir Book'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Avenir-BookOblique.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Avenir-BookOblique-02.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Avenir Medium'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Avenir-Medium.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Avenir-Medium.ttf') format('truetype'); }
@font-face { font-family: 'Avenir Medium'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Avenir-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Avenir-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'AvenirNext'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AvenirNext.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/AvenirNext.ttf') format('truetype'); }
@font-face { font-family: 'AvenirNext Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AvenirNext-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/AvenirNext-Bold.ttf') format('truetype'); }
@font-face { font-family: 'AvenirNext Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AvenirNext-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/AvenirNext-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'AvenirNext Bold'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/AvenirNext-BoldItalic-02.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/AvenirNext-BoldItalic-02.ttf') format('truetype'); font-weight: bold; font-style: italic; }
//@font-face { font-family: 'AdobeSongStd Light Alphabetic'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AdobeSongStd-Light-Alphabetic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/AdobeSongStdL.ttf') format('truetype'); }
@font-face { font-family: 'AdobeSongStd Light Alphabetic'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AdobeSongStdL.otf') format('opentype'); }
@font-face { font-family: 'AlienLeague'; src: url($REACT_APP_API_CDN_HOST + '/fonts/alienleagueii3d.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/alienleagueii3d.ttf') format('truetype'); }
@font-face { font-family: 'AlienLeague'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/AlienLeagueItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/alienleagueital.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'AlienLeague Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/alienleaguebold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/alienleaguebold.ttf') format('truetype'); }
@font-face { font-family: 'AlienLeague Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/alienleaguebold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/alienleaguebold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'AlienLeague Bold'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/AlienLeagueItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/alienleagueital.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'AlienLeague Bold'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/alien league bold italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/alien league bold italic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'AlternateGotNo2D'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AlternateGotNo2D.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/AlternateGotNo2D.ttf') format('truetype'); }
@font-face { font-family: 'Aptifer Slab'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AptiferSlabLTPro-Regular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/AptiferSlabLTPro-Regular.ttf') format('truetype'); }
@font-face { font-family: 'Aptifer Slab'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AptiferSlabLTPro-Black.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/AptiferSlabLTPro-Black.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Aptifer Slab'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AptiferSlabLTPro-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/AptiferSlabLTPro-Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Aptifer Slab'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Aptifer Slab LT Bold Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Aptifer Slab LT Bold Italic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'Aileron Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Aileron-Regular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Aileron-Regular.ttf') format('truetype'); }
@font-face { font-family: 'Aileron Regular'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Aileron-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Aileron-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Aileron Regular'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Aileron-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Aileron-Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Aileron Regular'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Aileron-BoldItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Aileron-BoldItalic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'Arcon Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Arcon-Regular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Arcon-Regular.ttf') format('truetype'); }
@font-face { font-family: 'ARWeiBei'; src: url($REACT_APP_API_CDN_HOST + '/fonts/ARWeiBei-TC.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/ARWeiBei-TC.ttf') format('truetype'); }
@font-face { font-family: 'Avante'; src: url($REACT_APP_API_CDN_HOST + '/fonts/avante.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/avante.ttf') format('truetype'); }
@font-face { font-family: 'Avante'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/AvanteBold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Avante Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Avante'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/AvantiItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Avante-Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Avante'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Avante-Bold-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Avante-Bold-Italic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'Ayar'; src: url($REACT_APP_API_CDN_HOST + '/fonts/ayar.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/ayar.ttf') format('truetype'); }
@font-face { font-family: 'Ayar'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/AyarKasone.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/ayar_Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Ayuthaya'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Ayuthaya.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Ayuthaya.ttf') format('truetype'); }
@font-face { font-family: 'Bakery'; src: url($REACT_APP_API_CDN_HOST + '/fonts/bakery.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/bakery.ttf') format('truetype'); }
@font-face { font-family: 'Baskerville'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Baskerville-01.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Baskerville-01.ttf') format('truetype'); }
@font-face { font-family: 'Baskerville'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Baskerville-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Baskerville-Bold-02.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Baskerville'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Baskerville-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Baskerville-Italic-03.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Baskerville'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Baskerville-BoldItalic-04.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Baskerville-BoldItalic-04.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'Baskervald'; src: url($REACT_APP_API_CDN_HOST + '/fonts/BaskervaldADFStd.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/BaskervaldADFStd.ttf') format('truetype'); }
@font-face { font-family: 'Baskervald'; src: url($REACT_APP_API_CDN_HOST + '/fonts/BaskervaldADFStd-Black.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/BaskervaldADFStd-Black.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Baskervald'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/BaskervaldADFStd-BoldItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/BaskervaldADFStd-BoldItalic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'Baskervald Italic'; src: url($REACT_APP_API_CDN_HOST + '/fonts/BaskervaldADFStd-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/BaskervaldADFStd-Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'BebasNeue Regular'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/BebasNeue-Regular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/BebasNeue-Regular.ttf') format('truetype'); }
@font-face { font-family: 'BebasNeue Regular'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/BebasNeueBold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/BebasNeueBold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'BodoniFLF Roman'; src: url($REACT_APP_API_CDN_HOST + '/fonts/BodoniFLF-Roman.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/BodoniFLF-Roman.ttf') format('truetype'); }
@font-face { font-family: 'BodoniFLF Roman'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/BodoniFLF-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/BodoniFLF-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'BodoniFLF Roman'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/BodoniFLF-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/BodoniFLF-Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'BodoniFLF Roman'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/BodoniFLF-BoldItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/BodoniFLF-BoldItalic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'BirchStd'; src: url($REACT_APP_API_CDN_HOST + '/fonts/BirchStd.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/BirchStd.ttf') format('truetype'); }
@font-face { font-family: 'BirchStd'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/BirchStd.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/BirchStd.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'BryantMedium'; src: url($REACT_APP_API_CDN_HOST + '/fonts/BryantMediumAltRegular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/BryantMediumAltRegular.ttf') format('truetype'); }
@font-face { font-family: 'Calibri'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Calibri.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Calibri.ttf') format('truetype'); }
@font-face { font-family: 'Calibri'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Calibri Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Calibri Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Calibri'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Calibri Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Calibri Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Calibri'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Calibri Bold Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Calibri Bold Italic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'Cheveuxdange'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Cheveuxdange.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Cheveuxdange.ttf') format('truetype'); }
@font-face { font-family: 'Colors Of Autumn'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Colors Of Autumn.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Colors Of Autumn.ttf') format('truetype'); }
@font-face { font-family: 'Comic Sans MS'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/ComicSansMS.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/COMIC.ttf') format('truetype'); }
@font-face { font-family: 'Comic Sans MS'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/ComicSansMS-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Comic Sans MS Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Comic Sans MS'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/ComicSansMS-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Comic Sans MS-italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Comic Sans MS'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Comic Sans MS-BoldItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Comic Sans MS-BoldItalic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'Consolas'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Consolas.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Consolas.ttf') format('truetype'); }
@font-face { font-family: 'Consolas'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Consolas Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Consolas Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Consolas'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Consolas Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Consolas Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Consolas'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Consolas Bold Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Consolas Bold Italic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'Courier New'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/CourierNewPSMT.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Courier New.ttf') format('truetype'); }
@font-face { font-family: 'Courier New'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/CourierNewPS-BoldMT.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Courier New Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Courier New'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/CourierNewPS-ItalicMT.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Courier New Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Courier New'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Courier New Bold Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Courier New Bold Italic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'CREDC'; src: url($REACT_APP_API_CDN_HOST + '/fonts/CREDC.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/CREDC.ttf') format('truetype'); }
@font-face { font-family: 'Dan Hand'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Dan_Hand.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Dan_Hand.ttf') format('truetype'); }
@font-face { font-family: 'Dead Island'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Dead Island.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Dead Island.ttf') format('truetype'); }
@font-face { font-family: 'Dimitri'; src: url($REACT_APP_API_CDN_HOST + '/fonts/DIMITRI_.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/DIMITRI_.ttf') format('truetype'); }
@font-face { font-family: 'DINbek'; src: url($REACT_APP_API_CDN_HOST + '/fonts/DINbek-Medium.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/DINbek-Medium.ttf') format('truetype'); }
@font-face { font-family: 'Din'; src: url($REACT_APP_API_CDN_HOST + '/fonts/ufonts.com_din-light.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/ufonts.com_din-light.ttf') format('truetype'); }
@font-face { font-family: 'Din'; src: url($REACT_APP_API_CDN_HOST + '/fonts/DINBold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/DINBold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Din'; src: url($REACT_APP_API_CDN_HOST + '/fonts/DINRegularItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/DINRegularItalic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Dolce Vita'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Dolce Vita.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Dolce Vita.ttf') format('truetype'); }
@font-face { font-family: 'Dolce Vita'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/DolceVitaHeavy-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Dolce Vita Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Elo Hand'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Elo Hand.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Elo Hand.ttf') format('truetype'); }
@font-face { font-family: 'Fighting Spirit 2'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Fighting Spirit 2.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Fighting Spirit 2.ttf') format('truetype'); }
@font-face { font-family: 'Fighting Spirit 2'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Fighting Spirit 2 bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Fighting Spirit 2 bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Fighting Spirit 2'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Fighting Spirit 2 ital.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Fighting Spirit 2 ital.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Folk Solid'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Folk solid.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Folk solid.ttf') format('truetype'); }
@font-face { font-family: 'FreeSans'; src: url($REACT_APP_API_CDN_HOST + '/fonts/FreeSans.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/FreeSans.ttf') format('truetype'); }
@font-face { font-family: 'FreeSans'; src: url($REACT_APP_API_CDN_HOST + '/fonts/FreeSansBold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/FreeSansBold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'FreeSans'; src: url($REACT_APP_API_CDN_HOST + '/fonts/FreeSansOblique.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/FreeSansOblique.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'FreeSans'; src: url($REACT_APP_API_CDN_HOST + '/fonts/FreeSansBoldOblique.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/FreeSansBoldOblique.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'FuturaBT'; src: url($REACT_APP_API_CDN_HOST + '/fonts/FuturaBT.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/FuturaBT.ttf') format('truetype'); }
@font-face { font-family: 'FuturaBT'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/FuturaLightItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/futura-light-italic-bt.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'FuturaLT'; src: url($REACT_APP_API_CDN_HOST + '/fonts/FuturaLT-Light.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Futura LT Light.ttf') format('truetype'); }
@font-face { font-family: 'Geared Slab'; src: url($REACT_APP_API_CDN_HOST + '/fonts/GearedSlab.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/GearedSlab.ttf') format('truetype'); }
@font-face { font-family: 'Geared Slab'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/GearedSlab-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/GearedSlab-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Georgia'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Georgia.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Georgia.ttf') format('truetype'); }
@font-face { font-family: 'Georgia'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Georgia-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Georgia Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Georgia'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Georgia-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Georgia Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Georgia'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Georgia Bold Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Georgia Bold Italic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'GlacialIndifference Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/GlacialIndifference-Regular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/GlacialIndifference-Regular.ttf') format('truetype'); }
@font-face { font-family: 'GlacialIndifference Regular'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/GlacialIndifference-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/GlacialIndifference-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'GlacialIndifference Regular'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/GlacialIndifference-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/GlacialIndifference-Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'GochiHand'; src: url($REACT_APP_API_CDN_HOST + '/fonts/GochiHand-Regular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/GochiHand-Regular.ttf') format('truetype'); }
@font-face { font-family: 'GrandHotel Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/GrandHotel-Regular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/GrandHotel-Regular.ttf') format('truetype'); }
@font-face { font-family: 'Gretoon'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Gretoon.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Gretoon.ttf') format('truetype'); }
@font-face { font-family: 'Helvetica Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Helvetica-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Helvetica-Bold-02.ttf') format('truetype'); }
@font-face { font-family: 'Helvetica Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Helvetica-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Helvetica-Bold-02.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Helvetica Bold'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Helvetica-BoldOblique-04.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Helvetica-BoldOblique-04.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'Helvetica Light'; src: url($REACT_APP_API_CDN_HOST + '/fonts/helvetica.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Helvetica-01.ttf') format('truetype'); }
@font-face { font-family: 'Helvetica Light'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Helvetica-LightOblique.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Helvetica-LightOblique-06.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'HelveticaNeue'; src: url($REACT_APP_API_CDN_HOST + '/fonts/HelveticaNeue-Thin.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/HelveticaNeue-Thin.ttf') format('truetype'); }
@font-face { font-family: 'HelveticaNeue'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/HelveticaNeue-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/HelveticaNeue-Bold-02.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'HelveticaNeue'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/HelveticaNeue-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/HelveticaNeue-Italic-03.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'HelveticaNeue'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/HelveticaNeue-BoldItalic-04.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/HelveticaNeue-BoldItalic-04.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'HelveticaNeue-Medium'; src: url($REACT_APP_API_CDN_HOST + '/fonts/HelveticaNeue-Medium.ttf') format('truetype'); }
@font-face { font-family: 'Himalaya'; src: url($REACT_APP_API_CDN_HOST + '/fonts/himalaya.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/himalaya.ttf') format('truetype'); }
@font-face { font-family: 'Impact'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Impact.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Impact.ttf') format('truetype'); }
@font-face { font-family: 'Jamilah'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Jamilah-Regular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Jamilah.ttf') format('truetype'); }
@font-face { font-family: 'JandaRomantic'; src: url($REACT_APP_API_CDN_HOST + '/fonts/JandaRomantic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/JandaRomantic.ttf') format('truetype'); }
@font-face { font-family: 'Jeepers'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Jeepers.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Jeepers.ttf') format('truetype'); }
@font-face { font-family: 'Jomolhari'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Jomolhari.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Jomolhari.ttf') format('truetype'); }
@font-face { font-family: 'Knewave'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Knewave-Regular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Knewave-Regular.ttf') format('truetype'); }
@font-face { font-family: 'Kreon'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Kreon.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Kreon.ttf') format('truetype'); }
@font-face { font-family: 'Kreon'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Kreon-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Kreon-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Krungthep'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Krungthep.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Krungthep.ttf') format('truetype'); }
@font-face { font-family: 'LaBelleAurore'; src: url($REACT_APP_API_CDN_HOST + '/fonts/LaBelleAurore.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/LaBelleAurore.ttf') format('truetype'); }
@font-face { font-family: 'LeagueGothic'; src: url($REACT_APP_API_CDN_HOST + '/fonts/LeagueGothic-Regular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/LeagueGothic-Regular.ttf') format('truetype'); }
@font-face { font-family: 'LeagueGothic'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/LeagueGothic-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/LeagueGothic-Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'lomowebpixel'; src: url($REACT_APP_API_CDN_HOST + '/fonts/ufonts.com_lomowebpixel-lt-std-5.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/ufonts.com_lomowebpixel-lt-std-5.ttf') format('truetype'); }
@font-face { font-family: 'Lucida Sans Unicode'; src: url($REACT_APP_API_CDN_HOST + '/fonts/LucidaSansUnicode.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/LucidaSansUnicode.ttf') format('truetype'); }
@font-face { font-family: 'MarkerFelt Wide'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MarkerFelt-Wide.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/MarkerFelt-Wide.ttf') format('truetype'); }
@font-face { font-family: 'MarkerFelt Thin'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MarkerFelt-Thin.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/MarkerFelt-Thin.ttf') format('truetype'); }
@font-face { font-family: 'MarkerFelt'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MarkerFelt.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/MarkerFelt.ttf') format('truetype'); }
@font-face { font-family: 'Marcsc'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Marcsc___.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Marcsc.ttf') format('truetype'); }
@font-face { font-family: 'Marvel'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Marvel-Regular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Marvel-Regular.ttf') format('truetype'); }
@font-face { font-family: 'Metropolis Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Metropolis-Regular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Metropolis-Regular.ttf') format('truetype'); }
@font-face { font-family: 'Metropolis Regular'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Metropolis-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Metropolis-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Metropolis Regular'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Metropolis-RegularItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Metropolis-RegularItalic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Metropolis Regular'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Metropolis-BoldItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Metropolis-BoldItalic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'Metropolis SemiBold'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Metropolis-SemiBold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Metropolis-SemiBold.ttf') format('truetype'); }
@font-face { font-family: 'Metropolis SemiBold'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Metropolis-SemiBoldItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Metropolis-SemiBoldItalic.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'MyriadPro BoldCond'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MyriadPro-BoldCond.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/MyriadPro-BoldCond.ttf') format('truetype'); }
@font-face { font-family: 'Neuzeit'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Neuzeit_S_LT_Book.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Neuzeit.ttf') format('truetype'); }
@font-face { font-family: 'Neuzeit Heavy'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Neuzeit_Heavy.ttf') format('truetype'); }
@font-face { font-family: 'Nickainley'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Nickainley.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Nickainley.ttf') format('truetype'); }
@font-face { font-family: 'OpenSans Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/OpenSans.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/OpenSans-Regular.ttf') format('truetype'); }
@font-face { font-family: 'OpenSans Regular'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/OpenSans-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/OpenSans-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'OpenSans Regular'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/OpenSans-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/OpenSans-Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'OpenSans Regular'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/OpenSans-BoldItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/OpenSans-BoldItalic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'OpenSans Semibold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/OpenSans-Semibold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/OpenSans-Semibold.ttf') format('truetype'); }
@font-face { font-family: 'OpenSans Semibold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/OpenSans-Semibold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/OpenSans-Semibold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'OpenSans Semibold'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/OpenSans-SemiboldItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/OpenSans-SemiboldItalic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Palatino Linotype'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/PalatinoLinotype-Roman.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Palatino Linotype.ttf') format('truetype'); }
@font-face { font-family: 'Palatino Linotype'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/PalatinoLinotype-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Palatino Linotype-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Palatino Linotype'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/PalatinoLinotype-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Palatino Linotype-Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Palatino Linotype'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Palatino Linotype-BoldItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Palatino Linotype-BoldItalic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'PingFangHK Light'; src: url($REACT_APP_API_CDN_HOST + '/fonts/PingFangHK-Light-Proportional-10.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/PingFangHK-Light-Proportional-10.ttf') format('truetype'); }
@font-face { font-family: 'PingFangHK Medium'; src: url($REACT_APP_API_CDN_HOST + '/fonts/PingFangHK-Medium-Proportional-04.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/PingFangHK-Medium-Proportional-04.ttf') format('truetype'); }
@font-face { font-family: 'PingFangHK Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/PingFangHK-Regular-Proportional-01.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/PingFangHK-Regular-Proportional-01.ttf') format('truetype'); }
@font-face { font-family: 'PingFangHK Semibold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/PingFangHK-Semibold-Proportional-07.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/PingFangHK-Semibold-Proportional-07.ttf') format('truetype'); }
@font-face { font-family: 'PingFangHK Semibold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/PingFangHK-Semibold-Proportional-07.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/PingFangHK-Semibold-Proportional-07.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'PlayfairDisplay'; src: url($REACT_APP_API_CDN_HOST + '/fonts/PlayfairDisplay-Regular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/PlayfairDisplay-Regular.ttf') format('truetype'); }
@font-face { font-family: 'PlayfairDisplay'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/PlayfairDisplay-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/PlayfairDisplay-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'PlayfairDisplay'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/PlayfairDisplay-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/PlayfairDisplay-Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'PlayfairDisplay'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/playfairdisplay_bolditalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/playfairdisplay_bolditalic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'Playlist Script'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Playlist-Script.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Playlist-Script.ttf') format('truetype'); }
@font-face { font-family: 'RomanSerif'; src: url($REACT_APP_API_CDN_HOST + '/fonts/RomanSerif.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/RomanSerif.ttf') format('truetype'); }
@font-face { font-family: 'RomanSerif Oblique'; src: url($REACT_APP_API_CDN_HOST + '/fonts/RomanSerif-Oblique.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/RomanSerif-Oblique.ttf') format('truetype'); }
@font-face { font-family: 'SAF Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/SAF-Regular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/SAF-Regular.ttf') format('truetype'); }
@font-face { font-family: 'Sensei Medium'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Sensei-Medium.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Sensei-Medium.ttf') format('truetype'); }
@font-face { font-family: 'SixCaps'; src: url($REACT_APP_API_CDN_HOST + '/fonts/SixCaps.woff') format('woff'), url($REACT_APP_API_CDN_HOST + 'fonts/SixCaps.ttf') format('truetype'); }
@font-face { font-family: 'sportsjersey'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/SportsJersey.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/sportsjersey.ttf') format('truetype'); }
@font-face { font-family: 'Times New Roman'; src: url($REACT_APP_API_CDN_HOST + '/fonts/TimesNewRoman.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/TimesNewRoman.ttf') format('truetype'); }
@font-face { font-family: 'Times New Roman'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/TimesNewRomanPS-BoldMT.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Times New Roman Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Times New Roman'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/TimesNewRomanPS-ItalicMT.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Times New Roman Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Times New Roman'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Times New Roman Bold Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Times New Roman Bold Italic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'Trade Gothic'; src: url($REACT_APP_API_CDN_HOST + '/fonts/trade-gothic-lt-1361519976.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/trade-gothic-lt-1361519976.ttf') format('truetype'); }
@font-face { font-family: 'Trade Gothic L'; src: url($REACT_APP_API_CDN_HOST + '/fonts/TradeGothicLT-Light.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/TradeGothicLT-Light.ttf') format('truetype'); }
@font-face { font-family: 'Trade Gothic L'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/TradeGothicLT-Oblique.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Trade Gothic LT Oblique.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Trade Gothic B'; src: url($REACT_APP_API_CDN_HOST + '/fonts/TradeGothicLT-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/TradeGothicLT-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Trebuchet MS'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Trebuchet-MS.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/TrebuchetMS.ttf') format('truetype'); }
@font-face { font-family: 'Trebuchet MS'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/TrebuchetMS-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Trebuchet MS Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Trebuchet MS'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/TrebuchetMS-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Trebuchet MS Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Trebuchet MS'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Trebuchet MS Bold Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Trebuchet MS Bold Italic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'Ubuntu Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Ubuntu-B.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Ubuntu-B.ttf') format('truetype'); }
@font-face { font-family: 'Ubuntu Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Ubuntu-B.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Ubuntu-B.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Ubuntu'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Ubuntu-Light.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Ubuntu-Light.ttf') format('truetype'); }
@font-face { font-family: 'Ubuntu Italic'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Ubuntu-LightItalic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Ubuntu-LightItalic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Verdana'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Verdana.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Verdana.ttf') format('truetype'); }
@font-face { font-family: 'Verdana'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Verdana-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Verdana Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Verdana'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Verdana-Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Verdana Italic.ttf') format('truetype'); font-style: italic; }
@font-face { font-family: 'Verdana'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/Verdana Bold Italic.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Verdana Bold Italic.ttf') format('truetype'); font-weight: bold; font-style: italic; }
@font-face { font-family: 'VastShadow'; src: url($REACT_APP_API_CDN_HOST + '/fonts/VastShadow-Regular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/VastShadow-Regular.ttf') format('truetype'); }
@font-face { font-family: 'yumin'; src: url($REACT_APP_API_CDN_HOST + '/fonts/yumin.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/yumin.ttf') format('truetype'); }
@font-face { font-family: 'ZCOOL Addict Italic'; src: url($REACT_APP_API_CDN_HOST + '/fonts/ZCOOL Addict Italic 02.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/ZCOOL Addict Italic 02.ttf') format('truetype'); }
@font-face { font-family: 'finaldemo'; src: url($REACT_APP_API_CDN_HOST + '/fonts/finaldemo.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/finaldemo.ttf') format('truetype'); }
@font-face { font-family: 'Amatic-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Amatic-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Amatic-Bold.ttf') format('truetype'); }
@font-face { font-family: 'Amatic-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Amatic-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/Amatic-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'AmaticSC-Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AmaticSC-Regular.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/AmaticSC-Regular.ttf') format('truetype'); }
@font-face { font-family: 'AmaticSC-Regular'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/AmaticSC-Bold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/AmaticSC-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Aurella'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Aurella.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Aurella.ttf') format('truetype'); }
@font-face { font-family: 'chalala'; src: url($REACT_APP_API_CDN_HOST + '/fonts/chalala.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/chalala.ttf') format('truetype'); }
@font-face { font-family: 'Cheri-Liney'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Cheri-Liney.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/CHERL_line.ttf') format('truetype'); }
@font-face { font-family: 'CHERI'; src: url($REACT_APP_API_CDN_HOST + '/fonts/CHERI.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/CHERI.ttf') format('truetype'); }
@font-face { font-family: 'CHERI'; src: url($REACT_APP_API_CDN_HOST + '/newfonts/CheriBold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/CHERIBold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'First-Crush'; src: url($REACT_APP_API_CDN_HOST + '/fonts/First-Crush.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/First Crush.ttf') format('truetype'); }
@font-face { font-family: 'peacechild-beta'; src: url($REACT_APP_API_CDN_HOST + '/fonts/PEACECHILD-BETA.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/peacechild-beta.ttf') format('truetype'); }
@font-face { font-family: 'PORKYS'; src: url($REACT_APP_API_CDN_HOST + '/fonts/PORKYS.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/PORKYS_.ttf') format('truetype'); }
@font-face { font-family: 'TheHistoriaDemo'; src: url($REACT_APP_API_CDN_HOST + '/fonts/TheHistoriaDemo.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/newfonts/TheHistoriaDemo.ttf') format('truetype'); }
@font-face { font-family: 'WindsorBOLD'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Windsor BOLD.ttf') format('truetype'); }
@font-face { font-family: 'WindsorBOLD'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Windsor BOLD.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'WindsorLtBTLight'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Windsor Lt BT Light.ttf') format('truetype'); }
@font-face { font-family: 'AppleLiGothicMedium'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AppleLiGothicMedium.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/AppleLiGothicMedium.ttf') format('truetype'); }
@font-face { font-family: 'FZCuHuoYi'; src: url($REACT_APP_API_CDN_HOST + '/fonts/FZCuHuoYi.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/FZCuHuoYi.ttf') format('truetype'); }
@font-face { font-family: 'dFYuanBold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/華康粗圓體.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/華康粗圓體.ttf') format('truetype'); }
@font-face { font-family: 'dFYuanBold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/華康粗圓體.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/華康粗圓體.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'DFHaiBao'; src: url($REACT_APP_API_CDN_HOST + '/fonts/DFHaiBaoStd-W9-BIG.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/DFHaiBao.ttf') format('truetype'); }
@font-face { font-family: 'DFPHannotateW5'; src: url($REACT_APP_API_CDN_HOST + '/fonts/DFPHannotateW5-B5-TW.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/DFPHannotateW5-B5-TW.ttf') format('truetype'); }
@font-face { font-family: 'DFLiKingHei'; src: url($REACT_APP_API_CDN_HOST + '/fonts/DFLiKingHei-XB-HK-BF.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/DFLiKingHei.ttf') format('truetype'); }
@font-face { font-family: 'HanziPen'; src: url($REACT_APP_API_CDN_HOST + '/fonts/HanziPen.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/HanziPen.ttf') format('truetype'); }
@font-face { font-family: 'HiraginoSansGBW3'; src: url($REACT_APP_API_CDN_HOST + '/fonts/HiraginoSansGB-W3.ttf') format('truetype'); }
@font-face { font-family: 'HiraginoSansGBW6'; src: url($REACT_APP_API_CDN_HOST + '/fonts/HiraginoSansGB-W6.ttf') format('truetype'); }
@font-face { font-family: 'MHeiHK-Bold'; src: /*url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Bold.woff') format('woff'), */url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Bold.ttf') format('truetype'); }
@font-face { font-family: 'MHeiHK-Bold'; src: /*url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Bold.woff') format('woff'), */url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Bold.ttf') format('truetype'); font-weight: bold;}
@font-face { font-family: 'MHeiHK-Light'; src: /*url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Light.woff') format('woff'), */url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Light.ttf') format('truetype'); }
@font-face { font-family: 'MHeiHK-Medium'; src: /*url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Medium.woff') format('woff'), */url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Medium.ttf') format('truetype'); }
@font-face { font-family: 'MHeiHK-Xbold'; src: /*url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Xbold.woff') format('woff'), */url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Xbold.ttf') format('truetype'); }
@font-face { font-family: 'MHeiHK-Xbold'; src: /*url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Xbold.woff') format('woff'), */url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Xbold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'MLingWaiFHK Light Roman'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MLingWaiFHK-Light-Roman.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/MLingWaiFHK-Light-Roman.ttf') format('truetype'); }
@font-face { font-family: 'MQingHuaHK'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MQingHuaHK.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/MQingHuaHK.ttf') format('truetype'); }
@font-face { font-family: 'MQingHuaHK'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MQingHuaHK-Xbold.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/MQingHuaHK-Xbold.ttf') format('truetype'); font-weight: bold; }
//@font-face { font-family: 'STHeitiTC'; src: url($REACT_APP_API_CDN_HOST + '/fonts/STHeitiTC-Light-01.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/STHeitiTC-Light-01.ttf') format('truetype'); }
@font-face { font-family: 'Minion3-Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Minion3-Regular.ttf') format('truetype'); }
@font-face { font-family: 'Minion3-Semibold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Minion3-Semibold.ttf') format('truetype'); }
@font-face { font-family: 'Minion3-Semibold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Minion3-Semibold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'MyriadPro-Light'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MyriadPro-Light.ttf') format('truetype'); }
@font-face { font-family: 'MyriadPro-Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MyriadPro-Regular.ttf') format('truetype'); }
@font-face { font-family: 'MyriadPro-Semibold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MyriadPro-Semibold.ttf') format('truetype'); }
@font-face { font-family: 'MyriadPro-Semibold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MyriadPro-Semibold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'MyriadPro-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MyriadPro-Bold.ttf') format('truetype'); }
@font-face { font-family: 'MyriadPro-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MyriadPro-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Arial-Rounded-MT-Light'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Arial-Rounded-MT-Light.ttf') format('truetype'); }
@font-face { font-family: 'Arial-Rounded-MT'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Arial_Rounded_MT.ttf') format('truetype'); }
@font-face { font-family: 'Arial-Rounded-MT-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Arial_Rounded_MT_Bold.ttf') format('truetype'); }
@font-face { font-family: 'Arial-Rounded-MT-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Arial_Rounded_MT_Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Arial_Rounded_MT_ExtraBold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Arial_Rounded_MT_ExtraBold.ttf') format('truetype'); }
@font-face { font-family: 'Arial_Rounded_MT_ExtraBold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Arial_Rounded_MT_ExtraBold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Athelas-Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Athelas-Regular.ttf') format('truetype'); }
@font-face { font-family: 'Athelas-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Athelas-Bold.ttf') format('truetype'); }
@font-face { font-family: 'Athelas-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Athelas-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'KozGoPr6N-Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/KozGoPr6N-Regular.otf') format('opentype'); }
@font-face { font-family: 'thonburi'; src: url($REACT_APP_API_CDN_HOST + '/fonts/thonburi.ttf') format('truetype'); }
@font-face { font-family: 'Thonburi_Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Thonburi_Bold.ttf') format('truetype'); }
@font-face { font-family: 'Thonburi_Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Thonburi_Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Copperplate Gothic Light Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Copperplate Gothic Light Regular.ttf') format('truetype'); }
@font-face { font-family: 'ArnoPro-Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/ArnoPro-Regular.ttf') format('truetype'); }
@font-face { font-family: 'AvantGardeBook'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AvantGardeBook.otf') format('opentype'), url($REACT_APP_API_CDN_HOST + '/fonts/AvantGardeBook.ttf') format('truetype'); }
@font-face { font-family: 'AvantGardeMedium'; src: url($REACT_APP_API_CDN_HOST + '/fonts/AvantGardeMedium.ttf') format('truetype'); }
@font-face { font-family: 'CenturyGothicRegular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/CenturyGothicRegular.ttf') format('truetype'); }
@font-face { font-family: 'CenturyGothicBold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/CenturyGothicBold.ttf') format('truetype'); }
@font-face { font-family: 'CenturyGothicBold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/CenturyGothicBold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Helvetica'; src: url($REACT_APP_API_CDN_HOST + '/fonts/helvetica.woff') format('woff'), url($REACT_APP_API_CDN_HOST + '/fonts/Helvetica.ttf') format('truetype'); }
@font-face { font-family: 'ITCAvantGardeStd-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/ITCAvantGardeStd-Bold.ttf') format('truetype'); }
@font-face { font-family: 'ITCAvantGardeStd-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/ITCAvantGardeStd-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'CHei2-Bold-HKSCS'; src: url($REACT_APP_API_CDN_HOST + '/fonts/CHei2-Bold-HKSCS.ttf') format('truetype'); }
@font-face { font-family: 'CHei2-Xbold-HKSCS'; src: url($REACT_APP_API_CDN_HOST + '/fonts/CHei2-Xbold-HKSCS.ttf') format('truetype'); }
@font-face { font-family: 'DFMing-Bd-HK-BF'; src: url($REACT_APP_API_CDN_HOST + '/fonts/DFMing-Bd-HK-BF.ttf') format('truetype'); }
@font-face { font-family: 'HanWangMingMedium'; src: url($REACT_APP_API_CDN_HOST + '/fonts/HanWangMingMedium.ttf') format('truetype'); }
@font-face { font-family: 'HanWangMingBold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/HanWangMingBold.ttf') format('truetype'); }
@font-face { font-family: 'HanWangMingBold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/HanWangMingBold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'LogoMaruStd-Light'; src: url($REACT_APP_API_CDN_HOST + '/fonts/LogoMaruStd-Light.ttf') format('truetype'); }
@font-face { font-family: 'LogoMaruStd-Medium'; src: url($REACT_APP_API_CDN_HOST + '/fonts/LogoMaruStd-Medium.ttf') format('truetype'); }
@font-face { font-family: 'LogoMaruStd-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/LogoMaruStd-Bold.ttf') format('truetype'); }
@font-face { font-family: 'LogoMaruStd-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/LogoMaruStd-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'LogoMaruStd-Extra'; src: url($REACT_APP_API_CDN_HOST + '/fonts/LogoMaruStd-Extra.ttf') format('truetype'); }
@font-face { font-family: 'LogoMaruStd-Ultra'; src: url($REACT_APP_API_CDN_HOST + '/fonts/LogoMaruStd-Ultra.ttf') format('truetype'); }
//@font-face { font-family: 'MHeiHK-Light'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Light.ttf') format('truetype'); }
//@font-face { font-family: 'MHeiHK-Medium'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Medium.ttf') format('truetype'); }
//@font-face { font-family: 'MHeiHK-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Bold.ttf') format('truetype'); }
//@font-face { font-family: 'MHeiHK-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Bold.ttf') format('truetype'); font-weight: bold; }
//@font-face { font-family: 'MHeiHK-Xbold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Xbold.ttf') format('truetype'); }
//@font-face { font-family: 'MHeiHK-Xbold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MHeiHK-Xbold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'MHei-Light'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MHei-Light.ttf') format('truetype'); }
@font-face { font-family: 'MHei-Medium'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MHei-Medium.ttf') format('truetype'); }
@font-face { font-family: 'MHeiSungHK-UltraBold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MHeiSungHK-UltraBold.ttf') format('truetype'); }
@font-face { font-family: 'MHeiSungHK-UltraBold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MHeiSungHK-UltraBold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'MSungHK-Light'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MSungHK-Light.ttf') format('truetype'); }
@font-face { font-family: 'MSungHK-Medium'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MSungHK-Medium.ttf') format('truetype'); }
@font-face { font-family: 'FuturaStd-Medium'; src: url($REACT_APP_API_CDN_HOST + '/fonts/FuturaStd-Medium.ttf') format('truetype'); }
@font-face { font-family: 'FuturaStd-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/FuturaStd-Bold.ttf') format('truetype'); }
@font-face { font-family: 'FuturaStd-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/FuturaStd-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'FontsFree-Net-FuturaStdHeavy'; src: url($REACT_APP_API_CDN_HOST + '/fonts/FontsFree-Net-FuturaStdHeavy.ttf') format('truetype'); }
@font-face { font-family: 'GillSans-Light-08'; src: url($REACT_APP_API_CDN_HOST + '/fonts/GillSans-Light-08.ttf') format('truetype'); }
@font-face { font-family: 'GillSans'; src: url($REACT_APP_API_CDN_HOST + '/fonts/GillSans.otf') format('opentype'); }
@font-face { font-family: 'KohinoorBangla-Medium'; src: url($REACT_APP_API_CDN_HOST + '/fonts/KohinoorBangla-Medium.ttf') format('truetype'); }
@font-face { font-family: 'KohinoorBangla-Semibold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/KohinoorBangla-Semibold.ttf') format('truetype'); }
@font-face { font-family: 'KohinoorBangla-Semibold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/KohinoorBangla-Semibold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'KohinoorBangla-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/KohinoorBangla-Bold.ttf') format('truetype'); }
@font-face { font-family: 'KohinoorBangla-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/KohinoorBangla-Bold.ttf') format('truetype'); font-weight: bold; }

@font-face { font-family: 'NotoSansCJKtc-Light'; src: url($REACT_APP_API_CDN_HOST + '/fonts/NotoSansCJKtc-Light.ttf') format('truetype'); }
@font-face { font-family: 'SourceHanSansCN-Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/SourceHanSansCN-Regular.ttf') format('truetype'); }
@font-face { font-family: 'SourceHanSansCN-Medium'; src: url($REACT_APP_API_CDN_HOST + '/fonts/SourceHanSansCN-Medium.ttf') format('truetype'); }
@font-face { font-family: 'SourceHanSansCN-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/SourceHanSansCN-Bold.ttf') format('truetype'); }
@font-face { font-family: 'SourceHanSansCN-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/SourceHanSansCN-Bold.ttf') format('truetype'); font-weight: bold; }

@font-face { font-family: 'SourceHanSerifCN-Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/SourceHanSerifCN-Regular.ttf') format('truetype'); }
@font-face { font-family: 'SourceHanSerifCN-Light'; src: url($REACT_APP_API_CDN_HOST + '/fonts/SourceHanSerifCN-Light.ttf') format('truetype'); }
@font-face { font-family: 'SourceHanSerifCN-Medium'; src: url($REACT_APP_API_CDN_HOST + '/fonts/SourceHanSerifCN-Medium.ttf') format('truetype'); }
@font-face { font-family: 'SourceHanSerifCN-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/SourceHanSerifCN-Bold.ttf') format('truetype'); }
@font-face { font-family: 'SourceHanSerifCN-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/SourceHanSerifCN-Bold.ttf') format('truetype'); font-weight: bold; }

@font-face { font-family: 'Novarese_Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Novarese_Bold.ttf') format('truetype'); }
@font-face { font-family: 'Novarese_Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Novarese_Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'Novarese_BookItalic'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Novarese_BookItalic.ttf') format('truetype'); }
@font-face { font-family: 'MinionPro-Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/MinionPro-Regular.ttf') format('truetype'); }

@font-face { font-family: 'PingFang-SC-Light'; src: url($REACT_APP_API_CDN_HOST + '/fonts/PingFang-SC-Light.ttf') format('truetype'); }
@font-face { font-family: 'Tw-Cen-MT'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Tw-Cen-MT.ttf') format('truetype'); }
@font-face { font-family: 'FZLTXHB--B51-0'; src: url($REACT_APP_API_CDN_HOST + '/fonts/FZLTXHB--B51-0.ttf') format('truetype'); }

@font-face { font-family: 'ITCAvantGardeStd-Bk'; src: url($REACT_APP_API_CDN_HOST + '/fonts/ITCAvantGardeStd-Bk.ttf') format('truetype'); }
@font-face { font-family: 'ITCAvantGardeStd-Demi'; src: url($REACT_APP_API_CDN_HOST + '/fonts/ITCAvantGardeStd-Demi.ttf') format('truetype'); }
@font-face { font-family: 'NotoSansCJKjp-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/NotoSansCJKjp-Bold.ttf') format('truetype'); }
@font-face { font-family: 'NotoSansCJKjp-Bold'; src: url($REACT_APP_API_CDN_HOST + '/fonts/NotoSansCJKjp-Bold.ttf') format('truetype'); font-weight: bold; }
@font-face { font-family: 'NotoSansCJKjp-Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/NotoSansCJKjp-Regular.ttf') format('truetype'); }
@font-face { font-family: 'SimHei'; src: url($REACT_APP_API_CDN_HOST + '/fonts/SimHei.ttf') format('truetype'); }
@font-face { font-family: 'SoukouMincho'; src: url($REACT_APP_API_CDN_HOST + '/fonts/SoukouMincho.ttf') format('truetype'); }
@font-face { font-family: 'NewCaledoniaLTStdRg'; src: url($REACT_APP_API_CDN_HOST + '/fonts/NewCaledoniaLTStdRg.ttf') format('truetype'); }
@font-face { font-family: 'Philosopher-Regular'; src: url($REACT_APP_API_CDN_HOST + '/fonts/Philosopher-Regular.ttf') format('truetype'); }
/**
    Reference: https://tt5.org/google-fonts-%E6%8F%90%E4%BE%9B%E4%BA%94%E7%A8%AE%E4%B8%AD%E6%96%87-wenfont%EF%BC%8C%E6%97%A9%E8%B5%B7%E7%9A%84%E9%B3%A5%E5%85%92%E6%9C%89%E7%9A%84%E7%8E%A9/
**/
@font-face {
        font-family: 'Fangsong';
        src: url(https://fonts.gstatic.com/ea/cwtexfangsong/v3/cwTeXFangSong-zhonly.woff) format('woff');
}

@font-face {
        font-family: 'Heiti';
        src: url(https://fonts.gstatic.com/ea/cwtexhei/v3/cwTeXHei-zhonly.woff) format('woff');
}

@font-face {
        font-family: '楷體';
        src: url(https://fonts.gstatic.com/ea/cwtexkai/v3/cwTeXKai-zhonly.woff) format('woff');
}

@font-face {
        font-family: '明體';
        src: url(https://fonts.gstatic.com/ea/cwtexming/v3/cwTeXMing-zhonly.woff) format('woff');
}

@font-face {
        font-family: '圓體';
        src: url(https://fonts.gstatic.com/ea/cwtexyen/v3/cwTeXYen-zhonly.woff) format('woff');
}

/**
    Reference: http://tt5.org/webfont-%E7%89%88%E7%9A%84%E6%80%9D%E6%BA%90%E9%BB%91%E9%AB%94%E7%B5%82%E6%96%BC%E4%BE%86%E4%BA%86%EF%BC%81/
**/
/*
* Noto Sans TC (Chinese_traditional) http://www.google.com/fonts/earlyaccess (思源黑體)
 */
@font-face {
        font-family: '思源黑體';
        font-style: normal;
        font-weight: 100;
        src: url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Thin.woff2) format('woff2'),
        url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Thin.woff) format('woff'),
        url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Thin.otf) format('opentype');
}
@font-face {
        font-family: '思源黑體';
        font-style: normal;
        font-weight: 300;
        src: url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Light.woff2) format('woff2'),
        url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Light.woff) format('woff'),
        url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Light.otf) format('opentype');
}
@font-face {
        font-family: 'Source Han';
        font-style: normal;
        font-weight: 400;
        src: url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.woff2) format('woff2'),
        url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.woff) format('woff'),
        url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Regular.otf) format('opentype');
}
@font-face {
        font-family: '思源黑體';
        font-style: normal;
        font-weight: 500;
        src: url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Medium.woff2) format('woff2'),
        url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Medium.woff) format('woff'),
        url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Medium.otf) format('opentype');
}
@font-face {
        font-family: '思源黑體';
        font-style: normal;
        font-weight: 700;
        src: url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Bold.woff2) format('woff2'),
        url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Bold.woff) format('woff'),
        url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Bold.otf) format('opentype');
}
@font-face {
        font-family: '思源黑體';
        font-style: normal;
        font-weight: 900;
        src: url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Black.woff2) format('woff2'),
        url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Black.woff) format('woff'),
        url(https://fonts.gstatic.com/ea/notosanstc/v1/NotoSansTC-Black.otf) format('opentype');
}

@font-face {
  font-family: "Open Sans Regular";
  src: url($REACT_APP_API_CDN_HOST + '/fonts/OpenSansRegular.woff2');
  font-display: swap;
}
@font-face {
  font-family: "Open Sans Light";
  src: url($REACT_APP_API_CDN_HOST + '/fonts/OpenSansLight.woff2');
  font-display: swap;
}
