$REACT_APP_API_CDN_HOST: 'https://d24vpepiy8g9c6.cloudfront.net';
.tab-bar-panel {
  display: block;
  position: fixed;
  .tab-bar {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 70px;
    background-color: #000;
    display: flex;
    justify-content: space-evenly;
    .tab-bar-item {
      text-align: center;
      padding: 10px;
      cursor: pointer;

      &.selected {
        background-color: #293039;
      }
      .tab-bar-icon {
        width: 30px;
        height: 30px;
      }
      .tab-bar-text {
        font-size: 12px;
        color: #999999;
        text-align: center;
      }
    }
  }

  .tab-content-panel {
    position: fixed;
    background-color: #293039;
    width: 100%;
    height: calc(100% - 70px);
    bottom: 70px;

    .close-btn {
      width: 40px;
      height: 40px;
      position: absolute;
      right: 10px;
      top: 10px;
      color: #999999;
    }

    .tab-content-wrapper {
      margin-top: 60px;
      overflow-y: scroll;
      overflow-x: hidden;
      height: calc(100% - 60px);
      .text_menu {
        margin: 10px;
        width: calc(100% - 20px);
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .tab-bar-panel {
    display: none;
  }
}
