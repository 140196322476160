$REACT_APP_API_CDN_HOST: 'https://d24vpepiy8g9c6.cloudfront.net';
.checkout-panel {
  color: black;
  width: 350px;
  border-left: solid 1px #ddd;
  -webkit-font-smoothing: antialiased;
  .checkout-panel__close-btn {
    position: absolute;
    right: 0;
    top: 0;
    padding: 12px;
    font-size: 16px;
    color: rgba(41,48,57,.3);
  }
  .header_outline {
    justify-content: center;
  }
  .checkout-panel__step-btn {
    margin: 5px;
    border: solid 1px black;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-content: center;
    line-height: 20px;
    vertical-align: middle;
    text-decoration: none;
    color: black;
    &.active {
      color: white;
      background-color: black;
    }
  }

  .checkout-panel__content-wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .checkout-panel__checkout-item {
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  span,div {
    font-size: 14px;
    &.strong {
      font-weight: bold;
    }
    &.light {
      color: rgba(41,48,57,.7);
      font-size: 12px;
    }
  }

  .checkout-panel__text-error {
    color: #f84856;
    &.small {
      font-size: 12px;
    }
  }

  .checkout-panel__content-slide {
    flex: 1 1 100px;
    overflow-y: scroll;
    padding: 0 20px;

    h3 {
      font-size: 20px;
      text-align: center;
      font-weight: 400;
    }
    h4 {
      font-size: 14px;
      font-weight: 400;
    }
    .joinprint_logo{
      height:25px;
    }
    .card_logo{
      height:25px;
    }
    .checkout-panel__section {
      margin-top: 40px;
      margin-left: 0;
      margin-right: 0;
    }

    // .checkout-panel__satisfation-box {
    //   background: #ddd;
    //   border-radius: 5px;
    //   .satisfation-box__img-container {
    //     min-height: 150px;
    //     img {
    //       width: 100%;
    //       border-radius: 5px;
    //     }
    //   }
    //   .satisfation-box__text-container {
    //     border-bottom-left-radius: 5px;
    //     border-bottom-right-radius: 5px;
    //     background-color: #edf0f2;
    //     padding: 5px 20px;
    //     .satisfation-box__title {
    //       font-size: 12px;
    //       font-weight: bold;
    //       line-height: 20px;
    //     }
    //     .satisfation-box__desc {
    //       margin: 0;
    //       font-size: 10px;
    //       line-height: 16px;
    //     }
    //   }
    // }

    .checkout-panel__checklist {
      list-style: none;
      padding-inline-start: 0;
      .checklist__item {
        display: flex;
        align-items: center;
        margin: 10px 0;
        &.disabled {
          color: rgba(41,48,57,.3);
        }
        .checklist__icon {
          &.disabled {
            img {
              opacity: .3;
            }
          }
          img {
            margin-top: 5px;
            margin-bottom: 0;
            margin-right: 10px;
            width: 16px;
          }
        }
      }
      .checklist__notice {
        font-size: 11px;
        margin-left: 28px;
        margin-top: -10px;
      }
      .checklist__notice-box {
        margin-left: 25px;
        border-radius: 5px;
        padding: 10px 20px;
        background-color: rgba(41,48,57,.1);
        font-size: 11px;
        &.success {
          background-color: #dbf3cc;
          color: rgba(14,19,24,.7);
        }
        &.warning {
          background-color: #ffedbd;
          color: rgba(14,19,24,.7);
        }
      }
      .checklist__btn {
        margin: 10px 0;
        display: inline-block;
        border-radius: 5px;
        background-color: #56B3CA;
        padding: 8px 15px;
        border: solid 1px #56B3CA;
        color: white;
      }
    }

    .checkout-panel__input-row {
      display: flex;
      flex-direction: row;

      .checkout-panel__input-group {
        margin: 0;
        flex: 1 auto;
        display: block;
        .input-group__input {
          display: inline-block;
        }
      }
    }

    .checkout-panel__input-group {
      margin: 20px 0;
      .input-group__spacer {
        width: 30px;
      }
      .input-group__combined-field {
        display: flex;
        .input-group__input {
          flex: 1 auto;
          display: inline-block;
        }
      }
      .input-group__label {
        display: block;
        font-size: 12px;
        margin-bottom: 5px;
      }
      .input-group__input {
        display: block;
        width: calc(100% - 20px);
        border-radius: 5px;
        border: solid 1px rgba(41,48,57,.3);
        padding: 10px;
        height: 34px;
        &.has-warning {
          border: solid 1px #f84856;
        }
        &:hover {
          border: solid 1px #999;
        }
        &:focus {
          outline: none;
          border: solid 1px #56B3CA;
        }
      }
    }

    .hosted-field {
      height: 40px;
    }

  }

  .checkout-panel__footer {
    overflow-y: scroll;
    flex: 0 0 140px;
    padding: 0 20px;
    .footer__btn-group {
      display: flex;
      flex-direction: row;
      .btn-group__spacer {
        width: 10px;
      }
      .footer__btn {
        margin-top: 5px;
        text-decoration: none;

        text-align: center;
        display: inline-block;
        border-radius: 5px;
        padding: 10px;
        // width: calc(100% - 20px);
        font-size: 16px;
        &.default {
          flex: 0 0 30px;
          background-color: white;
          color: rgba(41,48,57,.3);
          border: solid 1px rgba(41,48,57,.3);
          &:hover {
            background-color: rgba(41,48,57,.1);
          }
        }
        &.primary {
          flex: 1 1 0;
          background-color: #56B3CA;
          color: white;
          border: solid 1px #56B3CA;
          &:hover {
            background-color: #79BFD2;
          }
        }
        &.disabled {
          border: solid 1px rgba(41,48,57,.3);
          background-color: rgba(41,48,57,.3);
          &:hover {
            background-color: rgba(41,48,57,.1);
          }
        }
      }
    }

  }

  .StripeElement {
    box-sizing: border-box;
    height: 40px;
    padding: 10px 12px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    border: 1px solid #dee2e6!important;
  }

}
