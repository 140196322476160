.hide {
  display: none!important;
}

.inactive {
  opacity: 0.3;
}

html {
  -webkit-print-color-adjust: exact;
  -webkit-font-smoothing: antialiased;
}
