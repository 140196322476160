$REACT_APP_API_CDN_HOST: 'https://d24vpepiy8g9c6.cloudfront.net';
.form_style {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  min-height: 0;
  overflow: hidden;
}
.form_container {
  padding: 16px;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  min-height: 0;
}
.form_textbox {
  cursor: auto;
  border-radius: 5px;
  padding: 9px 12px 10px;
  outline: none;
  margin: 10px 0;
}
.from_btn_container {
  padding: 16px 0 0 0;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  min-height: 0;
}
.form_btn {
  border: 2px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  margin: 0;
  width: 100%;
  padding: 8px;
  background-color: #8c6ea8;
  outline: none;
}
.form_btn_flex_style {
  width: 100%;
  -webkit-box-orient: vertical;
  flex-direction: column;
  -webkit-box-pack: end;
  justify-content: flex-end;
}
