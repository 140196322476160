$REACT_APP_API_CDN_HOST: 'https://d24vpepiy8g9c6.cloudfront.net';
.zoomList {
  display: block;
  position: absolute;
  background-color: #FDFDFD;
  padding: 0;
  box-shadow: 0 0 0 1px rgba(14,19,24,.07), 0 2px 12px rgba(14,19,24,.2);
  color: #000;
  border-radius: 5px;
  z-index: 100;
  margin: 10px;
  right:0;
  bottom: 30px;
  &.hide {
    display: none;
  }
}
.zoom_label {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
.zoom_label_style {
  background-color: #FFF;
  border-radius: 8px;
  padding: 4px 8px;
  border: 2px solid #d4d4d4;
}
.zoom_btn {
  padding: 0 3px;
  cursor: pointer;
  margin: 0;
  height: 28px;
  min-width: 0;
  transition: background-color .3s ease,color .2s ease-out;
  display: inline-flex;
}
.zoom_text {
  color: #7F7F7F;
}
.enlarge_designtext {
  height: 100%;
  padding: 3px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7F7F7F;
  font-size: 12pt;
  max-width: 100%;
  overflow: hidden;
}
.zoom_text {
  font-size: 16px;
}
.zoom_text {
  box-shadow: none;
  background: none;
  text-align: center;
  padding: 0;
  border: 0;
  cursor: text;
  height: 24px;
  width: 40px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  outline: 0;
  box-sizing: border-box;
  font-variant-ligatures: none;
  -webkit-appearance: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.zoom_text:focus {
  border: 1px solid #fff;
  border-radius: 4px;
}
.zoom_check_icon {
  width:20px;
  height:20px;
  display: block;
  &.hide {
    display: none;
  }
}

