$REACT_APP_API_CDN_HOST: 'https://d24vpepiy8g9c6.cloudfront.net';


.social_icon_button {
  background-color: #e6563e;
  border-color: #e6563e;
  /*
  background-color: transparent;
  border: 2px solid #ffffff;
  */
  transition: color 0.2s, background-color 0.2s, opacity 0.2s;
  border-radius: 20px;
  padding: 4px;
  display: inline-flex;
  letter-spacing: .01rem;
  line-height: 1.4;
  max-width: 100%;

  &:hover {
    background-color: #F37E5D;
    border-color: #F37E5D;
    .text_box {
      opacity: 0.8;
    }
  }

  &.btn_active {
    background-color: #5C5595;
    border-color: #5C5595;
    opacity: 1;
    cursor: default;
    .text_box {
      opacity: 1;
    }
  }

  &.inactive {
    background-color: hsla(0,0%,100%,.8);
    opacity: 1;
    cursor: default;
    &:hover {
      background-color: hsla(0,0%,100%,.95);
    }
    .text_box {
      opacity: 0.5;
    }
  }

  .social_text_container {
    padding: 0 15px;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
  }

  .btn_text_container {
      height: 32px;
      min-width: 0;
      color: #0e1318;
      transition: background-color .3s ease,color .2s ease-out;
      -webkit-box-pack: center;
      justify-content: center;
  }

  .text_box {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    color: #ffffff;
    font-family: BryantMedium;
    font-size: 13pt;
    max-width: 100%;
    overflow: hidden;
    transition: color 0.2s, background-color 0.2s, opacity 0.2s;
  }

  .icon {
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    img {
      width: 20px;
    }
  }
}
.btn_primary {
  background-color: #56B3CA;
  border-color: #56B3CA;
  &:hover {
    background-color: #79BFD2;
    border-color: #79BFD2;
  }
  &.btn_active {
    background-color: #20A6c1;
    border-color: #20A6c1;
  }
}
.btn_artfia {
  background-color: #E6563E;
  border-color: #E6563E;
  &:hover {
    background-color: #F37E5D;
    border-color: #F37E5D;
  }
  &.btn_active {
    background-color: #20A6c1;
    border-color: #20A6c1;
  }
}
.publish_icon_button {
  .icon img {
    transition: all 100ms;
  }
  &:hover {
    .text_box {
      opacity: 1;
    }
  }

  &.btn_active {

    opacity: 1;
    cursor: default;
    .text_box {
      opacity: 1;
    }
    .icon img {
      -webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
      -moz-transform: rotate(180deg);        /* FF */
      -o-transform: rotate(180deg);          /* Opera */
      -ms-transform: rotate(180deg);         /* IE9 */
      transform: rotate(180deg);
      transition: all 100ms;
    }
  }
}
.text_nowrap {
  white-space: nowrap!important;
}

@media only screen and (max-width: 1000px) {
  .social_icon_button {
    padding:0;
  }
  .social_text_container{
    padding:0 !important;
  }
  .social_icon_button{
    border:none;
  }
  .social_icon_button .btn_text{
    /*display:none;*/
    padding:5px 15px;
  }
}
