.image_content {
  position: absolute;
}
.filter_basic:after, .filter_basic:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    pointer-events: none;
}
.filter_1977:after {
    z-index: 3;
    background: rgba(243,106,188,.3);
    mix-blend-mode: screen;
}
.filter_aden::after {
    background: -webkit-linear-gradient(left,rgba(66,10,14,.2),transparent);
    background: linear-gradient(to right,rgba(66,10,14,.2),transparent);
    mix-blend-mode: darken;
    z-index: 3;
}
.filter_brannan::after {
    background-color: rgba(161,44,199,.31);
    mix-blend-mode: lighten;
    z-index: 3;
}
.filter_brooklyn::after {
    background: -webkit-radial-gradient(circle,rgba(168,223,193,.4) 70%,#c4b7c8);
    background: radial-gradient(circle,rgba(168,223,193,.4) 70%,#c4b7c8);
    mix-blend-mode: overlay;
    z-index: 3;
}
.filter_clarendon:after {
    z-index: 3;
}
.filter_earlybird::after {
    background: -webkit-radial-gradient(circle,#d0ba8e 20%,#360309 85%,#1d0210 100%);
    background: radial-gradient(circle,#d0ba8e 20%,#360309 85%,#1d0210 100%);
    mix-blend-mode: overlay;
    z-index: 3;
}
.filter_gingham::after {
    background: #e6e6fa;
    z-index: 3;
}
.filter_gingham::after, .filter_perpetua::after, .filter_reyes::after {
    mix-blend-mode: soft-light;
}
.filter_hudson::after {
    background: -webkit-radial-gradient(circle,#a6b1ff 50%,#342134);
    background: radial-gradient(circle,#a6b1ff 50%,#342134);
    mix-blend-mode: multiply;
    opacity: .5;
    z-index: 3;
}
.filter_inkwell:after {
    z-index: 3;
}
.filter_kelvin::after {
    background: #b77d21;
    mix-blend-mode: overlay;
    z-index: 3;
}
.filter_lark::after {
    background: rgba(242,242,242,.8);
    mix-blend-mode: darken;
    z-index: 3;
}
.filter_lofi::after {
    background: -webkit-radial-gradient(circle,transparent 70%,#222 150%);
    background: radial-gradient(circle,transparent 70%,#222 150%);
    mix-blend-mode: multiply;
    z-index: 3;
}
.filter_maven:after {
    background: rgba(3,230,26,.2);
    mix-blend-mode: hue;
    z-index: 3;
}
.filter_mayfair::after {
    background: -webkit-radial-gradient(40% 40%,circle,rgba(255,255,255,.8),rgba(255,200,200,.6),#111 60%);
    background: radial-gradient(circle at 40% 40%,rgba(255,255,255,.8),rgba(255,200,200,.6),#111 60%);
    mix-blend-mode: overlay;
    opacity: .4;
    z-index: 3;
}
.filter_moon::after {
    background: #383838;
    mix-blend-mode: lighten;
    z-index: 3;
}
.filter_nashville::after {
    background: rgba(0,70,150,.4);
    mix-blend-mode: lighten;
    z-index: 3;
}
.filter_perpetua::after {
    background: -webkit-linear-gradient(top,#005b9a,#e6c13d);
    background: linear-gradient(to bottom,#005b9a,#e6c13d);
    opacity: .5;
    z-index: 3;
}
.filter_reyes::after {
    background: #efcdad;
    opacity: .5;
    z-index: 3;
}
.filter_rise::after {
    background: -webkit-radial-gradient(circle,rgba(232,197,152,.8),transparent 90%);
    background: radial-gradient(circle,rgba(232,197,152,.8),transparent 90%);
    mix-blend-mode: overlay;
    opacity: .6;
    z-index: 3;
}
.filter_slumber::after {
    background: rgba(125,105,24,.5);
    mix-blend-mode: soft-light;
    z-index: 3;
}
.filter_stinson::before {
    background: rgba(240,149,128,.2);
    mix-blend-mode: soft-light;
}
.filter_toaster::after {
    background: -webkit-radial-gradient(circle,#804e0f,#3b003b);
    background: radial-gradient(circle,#804e0f,#3b003b);
    mix-blend-mode: screen;
    z-index: 3;
}
.filter_valencia::after {
    background: #3a0339;
    mix-blend-mode: exclusion;
    opacity: .5;
    z-index: 3;
}
.filter_walden::after {
    background: #04c;
    mix-blend-mode: screen;
    opacity: .3;
    z-index: 3;
}
.filter_willow::before {
    background-image: radial-gradient(40%,circle,#d4a9af 55%,#000 150%);
    mix-blend-mode: overlay;
    z-index: 2;
}
.filter_willow::after {
    background-color: #d8cdcb;
    mix-blend-mode: color;
    z-index: 3;
}
.filter_xpro2::after {
    background: -webkit-radial-gradient(circle,#e6e7e0 40%,rgba(43,42,161,.6) 110%);
    background: radial-gradient(circle,#e6e7e0 40%,rgba(43,42,161,.6) 110%);
    mix-blend-mode: color-burn;
    z-index: 3;
}
