.header {
	min-width: 800px;
	height: 50px;
	/*background: linear-gradient(90deg,#db9ec5,#5b65ad);*/
    background-color: #FFF;
    box-shadow: 0 1px 0 rgba(14,19,24,.15);
    z-index:99;
}
.css_header {
	width: 100%;
	height: 50px;
	padding:0;
	display : flex;
	align-items: flex-start;
    justify-content: flex-start;
	color : #fff;
}

.joindesign_logo {
	flex: 0 0 150px;
	width: 170px;
	height: 100%;
	display: flex;
    align-items: center;
	justify-content: center;
}
.joindesign_logo>a{
  display:flex;
  align-self: center;
  justify-content: center;
}
.logo_img{
	width: 100px;
	align-self: flex-start;
}
.left_header_group {
	width:500px;
	height:100%;
	display : flex;
	align-items: center;
    justify-content: flex-start;
}
.center_header_group {
	width:10%;
	height:100%;
	display : flex;
	align-items: center;
    justify-content: flex-start;
}
.right_header_group {
	width:70%;
	height:100%;
	display : flex;
    align-items: center;
    justify-content: flex-end;
	margin-right: 5px;
}

.header_item {
	padding: 4px 8px;
	cursor: pointer;
	font-family: BryantMedium;
	font-size: 12pt;
}

.save_status_text {
  color: rgba(145,145,145,1);
  font-size: 14px;
  margin-left: 8px;
  width:150px;
  cursor:pointer;
  letter-spacing:0.4px;
}

.file_header_item {
	padding: 0 8px;
	border-right: 1px solid #ddd;
	margin-right: 8px;
}
.icon_header_item {
	padding: 4px;
}

.action_group {
	height: 100%;
}

.next_btn{
	margin-left: 25px;
	margin-right: 25px;
	cursor: pointer;
	font-size:18px;
	background-color:#0b7c18;
	padding:8px;
	border-radius:5px;
}

.next_btn.clicked{
	opacity: 0.5;
}

.share_header_content {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
}
.share_header_content_boader {
	background-color: #fff;
	padding: 0 6px;
	display: inline-flex;
	height: 35px;
	letter-spacing: .01rem;
	line-height: 1.4;
	max-width: 100%;
	min-width: 80px;
	border-radius: 4px;
	border: 1px solid #db9ec5;
}
.img_box {
	display: inline-flex;
	-webkit-box-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	align-items: center;
	width: 24px;
	height: 24px;
	padding-right:8px;
}
.text_box {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	width: 100%;
	color: #8c6ea8;
	font-size: 11pt;
	max-width: 100%;
	overflow: hidden;
}
.share_popup_label {
	padding: 4px;
	padding-bottom: 3px;
	border-bottom: 1px solid rgba(14,19,24,.15);
}
.popup_label_container {
	padding: 0 8px;
	height: 40px;
}
.label_content {
	padding: 0;
	-webkit-box-align: center;
	align-items: center;
	display: -webkit-box;
	display: flex;
	height: 100%;
	line-height: 1.6;
}
.share_popup_content_box {
	overflow: hidden;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	display: flex;
	min-height: 0;
	border-top: 1px solid rgba(14,19,24,.15)
}
.share_popup_container {
	overflow-y: auto;
	-webkit-box-flex: 1;
	flex: 1 1 auto;
	z-index: 0;
	will-change: transform;
  margin:10px;
}
/*
.download_popup_container .share_popup_container{
  border:1px solid #CCC;
  border-radius:10px;
}


.download_popup_container .share_popup_container .picker_list{
  padding:0px;
}

.download_popup_container .share_popup_container .picker_list .list_style{
  cursor:pointer;
}

.download_popup_container .share_popup_container .picker_list .list_style:not(:last-child){
  border-bottom:1px solid #CCC;
}

.download_popup_container .share_popup_container .picker_list .list_style:hover{
  background-color:#CCC;
}
*/

.share_content_item {
	padding: 16px;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	flex-direction: row;
	flex-wrap: wrap;
}
.link_content {
	margin-top: 0;
	padding: 6px;
	outline: none;
	top: 0;
}
.link_input_box {
	box-shadow: inset 0 0 0 1px rgba(14,19,24,.2);
	border-radius: 4px;
}
.link_input_margin {
	margin-left: 6px;
	width: 80%;
}
.link_input {
	border: none;
	z-index: 2;
	outline: none;
	width: 100%;
}
.share_copy_link {
	justify-content: center;
}
.link_title:hover {
	color: #8c6ea8;

}
.share_popup_header {
	color: #293039;
	-webkit-box-flex: 1;
	flex: 1;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	transition: color .1s ease-in-out;
	margin: 0 8px;
	text-align: left;
}
.share_popup_close {
	margin-left: 0;
	margin-right: 4px;
	-webkit-box-pack: end;
	justify-content: flex-end;
	min-width: auto;
	text-align: center;
}
.cancel_img {
	width: 15px;
}
.cursor_pointer {
	cursor: pointer;
}
.header_img {
	width: 40px;
}
.header_file_img {
	width: 50px;
}
.project_title_box {
	margin: 0 4px;
	max-width: 448px;
	overflow: hidden;
	position: relative;
}
.project_title_text {
	box-sizing: border-box;
	cursor: text;
	width: 100%;
	padding: 9px 12px;
	box-shadow: none;
	border: 1px solid transparent;
	border-radius: 4px;
	background: transparent;
	margin: 0;
	outline: none;
	position: absolute;
}
.project_title_font {
	color: #919191;
	font-size: 16px;
	font-family: BryantMedium;
	text-align: center;
	height: 35px;
}
.project_title_div {
	position: relative;
	opacity: 0;
	white-space: pre;
	pointer-events: none;
}
.project_title_text:hover, .project_title_text:focus {
	border: 1px solid #fff;
	padding: 0;
}

.publish_item {
	margin-top: 0;
	margin-bottom: 0;
	cursor: pointer;
	padding-left: 15px;
	outline: none;
  display:flex;
  align-items: center;
  justify-content: flex-start;
}
.publish_share {
	outline: none;
}
.publish_share_item {
	padding: 8px 15px 8px 15px;
	align-items: center;
	display: flex;
	height: 100%;
	line-height: 1.6;
}
.social_item_icon {
	width: 22px;
}
.project_title_font_active {
	color: #E6563E;
}
.fb_page_content_box {
	overflow: hidden;
	flex-direction: column;
	display: flex;
	min-height: 0;
}
.fb_page_input_margin {
	width: 100%
}
.fb_page_content_item {
	padding: 10px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  transition: all 600ms;
}
.modal_resize {
	width:0;
}
.modal_active {
	width: 100%; /* Full width */
}


/* Modal Content/Box */
.modal-content {
  background-color: rgba(255,255,255,0.8);
  margin: 10% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 550px; /* Could be more or less, depending on screen size */
  border-radius: 10px;
  color: #212529;
  min-height:300px;
}

/* Modal Content/Box */
.modal-alert-content {
  background-color: rgba(255,255,255,1);
  margin: 10% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  max-width: 400px; /* Could be more or less, depending on screen size */
  border-radius: 10px;
  color: #212529;
  min-height:120px;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor:pointer;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.line {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}
#folderModal .line:not(.folder){
  border-bottom:2px solid #5d4e6f;
}
#folderModal .line.folder{
  border-bottom:1px solid #EDEDED;
}
#folderModal .modal-content{
  overflow-y:auto;
}
.folder_elements{
  max-height:300px;
  overflow-y:auto;
}
.icon {
    width: 30px;
    height: auto;
    margin-right: 10px;
}
.alarm_icon {
	padding-top:6px;
}
.btn {
  display: inline-block !important;
  margin-bottom: 0 !important;
  font-weight: normal !important;
  text-align: center !important;
  white-space: nowrap !important;
  vertical-align: middle !important;
  -ms-touch-action: manipulation !important;
  touch-action: manipulation !important;
  cursor: pointer !important;
  background-image: none !important;
  border: 1px solid transparent !important;
  padding: 6px 12px !important;
  font-size: 14px !important;
  line-height: 1.42857143 !important;
  border-radius: 4px !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn:hover,
.btn:focus,
.btn.focus {
  color: #333333;
  text-decoration: none;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  opacity: 0.65;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}
.btn-default {
  color: #333333;
  background-color: #ffffff;
  border-color: #cccccc;
}
.btn-default:focus,
.btn-default.focus {
  color: #333333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}
.btn-default:hover {
  color: #333333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #333333;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #adadad;
}
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  color: #333333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus {
  background-color: #ffffff;
  border-color: #cccccc;
}
.btn-default .badge {
  color: #ffffff;
  background-color: #333333;
}
.btn-primary {
  color: #ffffff;
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #ffffff;
  background-color: #286090;
  border-color: #122b40;
}
.btn-primary:hover {
  color: #ffffff;
  background-color: #286090;
  border-color: #204d74;
}
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  color: #ffffff;
  background-color: #286090;
  background-image: none;
  border-color: #204d74;
}
.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
  color: #ffffff;
  background-color: #204d74;
  border-color: #122b40;
}
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus {
  background-color: #337ab7;
  border-color: #2e6da4;
}
.btn-primary .badge {
  color: #337ab7;
  background-color: #ffffff;
}
.btn-success {
  color: #ffffff !important;
  background-color: #5cb85c !important;
  border-color: #4cae4c !important;
}
.btn-success:focus,
.btn-success.focus {
  color: #ffffff !important;
  background-color: #449d44 !important;
  border-color: #255625 !important;
}
.btn-success:hover {
  color: #ffffff !important;
  background-color: #449d44 !important;
  border-color: #398439;
}
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  color: #ffffff;
  background-color: #449d44;
  background-image: none;
  border-color: #398439;
}
.btn-success:active:hover,
.btn-success.active:hover,
.open > .dropdown-toggle.btn-success:hover,
.btn-success:active:focus,
.btn-success.active:focus,
.open > .dropdown-toggle.btn-success:focus,
.btn-success:active.focus,
.btn-success.active.focus,
.open > .dropdown-toggle.btn-success.focus {
  color: #ffffff;
  background-color: #398439;
  border-color: #255625;
}
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled.focus,
.btn-success[disabled].focus,
fieldset[disabled] .btn-success.focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-success .badge {
  color: #5cb85c;
  background-color: #ffffff;
}
.btn-info {
  color: #ffffff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
.btn-info:focus,
.btn-info.focus {
  color: #ffffff;
  background-color: #31b0d5;
  border-color: #1b6d85;
}
.btn-info:hover {
  color: #ffffff;
  background-color: #31b0d5;
  border-color: #269abc;
}
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  color: #ffffff;
  background-color: #31b0d5;
  background-image: none;
  border-color: #269abc;
}
.btn-info:active:hover,
.btn-info.active:hover,
.open > .dropdown-toggle.btn-info:hover,
.btn-info:active:focus,
.btn-info.active:focus,
.open > .dropdown-toggle.btn-info:focus,
.btn-info:active.focus,
.btn-info.active.focus,
.open > .dropdown-toggle.btn-info.focus {
  color: #ffffff;
  background-color: #269abc;
  border-color: #1b6d85;
}
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled.focus,
.btn-info[disabled].focus,
fieldset[disabled] .btn-info.focus {
  background-color: #5bc0de;
  border-color: #46b8da;
}
.btn-info .badge {
  color: #5bc0de;
  background-color: #ffffff;
}
.btn-warning {
  color: #ffffff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning:focus,
.btn-warning.focus {
  color: #ffffff;
  background-color: #ec971f;
  border-color: #985f0d;
}
.btn-warning:hover {
  color: #ffffff;
  background-color: #ec971f;
  border-color: #d58512;
}
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  color: #ffffff;
  background-color: #ec971f;
  background-image: none;
  border-color: #d58512;
}
.btn-warning:active:hover,
.btn-warning.active:hover,
.open > .dropdown-toggle.btn-warning:hover,
.btn-warning:active:focus,
.btn-warning.active:focus,
.open > .dropdown-toggle.btn-warning:focus,
.btn-warning:active.focus,
.btn-warning.active.focus,
.open > .dropdown-toggle.btn-warning.focus {
  color: #ffffff;
  background-color: #d58512;
  border-color: #985f0d;
}
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled.focus,
.btn-warning[disabled].focus,
fieldset[disabled] .btn-warning.focus {
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning .badge {
  color: #f0ad4e;
  background-color: #ffffff;
}
.btn-danger {
  color: #ffffff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-danger:focus,
.btn-danger.focus {
  color: #ffffff;
  background-color: #c9302c;
  border-color: #761c19;
}
.btn-danger:hover {
  color: #ffffff;
  background-color: #c9302c;
  border-color: #ac2925;
}
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  color: #ffffff;
  background-color: #c9302c;
  background-image: none;
  border-color: #ac2925;
}
.btn-danger:active:hover,
.btn-danger.active:hover,
.open > .dropdown-toggle.btn-danger:hover,
.btn-danger:active:focus,
.btn-danger.active:focus,
.open > .dropdown-toggle.btn-danger:focus,
.btn-danger:active.focus,
.btn-danger.active.focus,
.open > .dropdown-toggle.btn-danger.focus {
  color: #ffffff;
  background-color: #ac2925;
  border-color: #761c19;
}
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled.focus,
.btn-danger[disabled].focus,
fieldset[disabled] .btn-danger.focus {
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-danger .badge {
  color: #d9534f;
  background-color: #ffffff;
}
.btn-custom {
  color: #ffffff !important;
  background-color: #7A73B3 !important;
  border-color: #5C559D !important;
}
.btn-custom:focus,
.btn-custom.focus {
  color: #ffffff !important;
  background-color: #5C559D !important;
  border-color: #48437A !important;
}
.btn-custom:hover {
  color: #ffffff !important;
  background-color: #5C559D !important;
  border-color: #48437A;
}
.btn-custom:active,
.btn-custom.active,
.open > .dropdown-toggle.btn-success {
  color: #ffffff;
  background-color: #5C559D;
  background-image: none;
  border-color: #48437A;
}
.btn-custom:active:hover,
.btn-custom.active:hover,
.open > .dropdown-toggle.btn-custom:hover,
.btn-custom:active:focus,
.btn-custom.active:focus,
.open > .dropdown-toggle.btn-custom:focus,
.btn-custom:active.focus,
.btn-custom.active.focus,
.open > .dropdown-toggle.btn-custom.focus {
  color: #ffffff;
  background-color: #5C559D;
  border-color: #48437A;
}
.btn-custom.disabled:hover,
.btn-custom[disabled]:hover,
fieldset[disabled] .btn-custom:hover,
.btn-custom.disabled:focus,
.btn-custom[disabled]:focus,
fieldset[disabled] .btn-custom:focus,
.btn-custom.disabled.focus,
.btn-custom[disabled].focus,
fieldset[disabled] .btn-custom.focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-custom .badge {
  color: #5cb85c;
  background-color: #ffffff;
}
.btn-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
}
.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
}
.btn-link:hover,
.btn-link:focus {
  color: #23527c;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
  color: #777777;
  text-decoration: none;
}
.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}
.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
.animate_icon {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}


.share_permissions_div{
  display:block;
  width:100%;
}

.share_permission_padding{
  padding:0;
}

.scope_title{
  padding: 5px 20px;
  font-size:14px;
  background-color:#8f7cb7;
  color:#FFF;
}

.before_team_share{
  padding:10px 20px;
  font-size:14px;
  /*border-bottom:1px solid #CCC;*/
  font-family:BryantMedium !important;
  line-height:1.5;
}

.qr_code_publish {
	padding: 25px 50px;
	text-align: center;
}
.qr_text_publish {
	padding: 25px;
	text-align: center;
	color: #293039;
	font-size: 14pt;
	font-family: BryantMedium;
	text-decoration: underline;
}
.folder{
  cursor:pointer;
  margin: 5px 0;
  padding:5px;
}
.folder:hover{
  background-color:#e4c6ff;
}

.folder_bg_1{
  background-color:#db1a1a;
}

.folder_bg_2{
  background-color:#0e6e23;
}

.folder_bg_3{
  background-color:#091d54;
}

.add_folder{
  margin:10px 0;
  padding-bottom:5px;
  /*border-bottom:1px solid #AAA;*/
  display:flex;
  align-items: center;
  justify-content: flex-start;
}

.add_folder_name{
  background:none;
  border: none;
  border-bottom:2px solid #8c6ea8;
  font-size:18px;
  width:calc(100% - 300px);
  flex-grow:1;
}

.add_folder_btn_div{
  width:100%;
  display:flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.add_folder_btn{
  background-color:#8c6ea8;
  color:#FFF;
  border:none;
  border-radius:5px;
  padding:5px 10px;
  font-size:16px;
  width:100px;
  cursor:pointer;
  margin: 0 15px 20px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.add_folder_btn.disabled{
  background-color:#AAA;
}

.folder_plus_icon{
  font-size:28px;
  border:1px dashed #AAA;
  width:50px;
  height:50px;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-right:10px;
}

.folder_add_header{
  font-size:16px;
  margin-right:5px;
}

.folder_col0{
  width:45px;
  height:45px;
  display:flex;
  align-items: center;
  justify-content: center;
  color:#FFF;
  margin-right:10px;
  border-radius:100%;
  font-weight:bold;
  font-size:20px;
}

.folder_col1{
  width:auto;
  flex-grow:1;
}

.folder_col2{
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height:40px;
}

.folder_col2 img{
  width:20px;
  height:20px;
}

.resize_container {
	width: 100%;
	display: flex;
	margin:5px;
	justify-content: center;
}
.resize_container_box {
	margin: 10px;
}
.resize_text {
	width: 150px;
	display: block;
	padding: 8px;
	font-size: 1rem;
	line-height: 1.5;
	color: #495057;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #ced4da;
	border-radius: .25rem;
	transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.resize_select {
	height: 100%;
}
.reisze_modal-content {
	min-height: 100px;
	border-radius: 4px;
}
.resize_btn {
	background-color: #8c6ea8 !important;
	color: #ffffff !important;
}
.text_danger {
	color: red;
	font-size: 16px;
	font-family: BryantMedium;
	text-align: center;
	height: 35px;
}
.text-center {

  display : flex;
  align-items: center;
    justify-content: flex-start;
      text-align:center !important;
}
.row{ display:-ms-flexbox;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;}

.save_btn{
  cursor:pointer;
  margin-left:15px;
}

.save_icon{
  width:18px;
  height:18px;
  margin-top:5px;
}
.alert_box_title {
	justify-content: center;
}
.resend_veification {
	text-align: center;
	margin: 10px;
}
.resend_veification a {
	color: #db9ec5;
	font-size: 14pt;
}
 .card_logo{
  height:25px;
}
.checkout-panel__section {
  margin:20px;
}
.checkout-panel__input-group {
    margin: 20px 0;
}
.input-group__label {
    display: block;
    font-size: 12px;
    margin-bottom: 5px;
}
.input-group__input {
    display: block;
    width: calc(100% - 20px);
    border-radius: 5px;
    border: solid 1px rgba(41, 48, 57, 0.3);
    padding: 10px;
    height: 14px;
}

.checkout-panel__hosted-field.input-group__input {
    height: 34px;
}

.hosted-field {
  height: 40px;
}
.checkout-panel__input-row {
  display: flex;
  flex-direction: row;
}

.checkout-panel__input-group {
  margin:10px 0 0 0;
  flex: 1 auto;
  display: block;
}
.input-group__combined-field {
  display: flex;
}
.input-group__input {
  flex: 1 auto;
  display: inline-block;
}
.download_btn_section {
  margin-top:0;
  width:100%;
  text-align: center;
}

.footer__btn {
  margin-top: 0;
  text-decoration: none;

  text-align: center;
  display: inline-block;
  border-radius: 5px;
  padding: 6px;
  font-size: 12px;
}
.primary {
  flex: 1 1 0;
  background-color: #56B3CA;
  color: white;
  border: solid 1px #56B3CA;
}
.primary:hover {
  background-color: #79BFD2;
}
.pdf_item {
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  padding-left: 15px;
  outline: none;
  width:60%;
  height:100%;
  display : flex;
  align-items: center;
  justify-content: flex-start;
}
.price_item{
    width: 40%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    margin-right: 5px;
}

.strong {
  font-size: 14px;
  font-weight: bold;
}
.checkout-panel__text-error {
  color: #f84856;
  font-size: 12px;
}

.cp_resize_btn{
  margin:0;
  padding:0 10px;
  cursor:pointer;
  /*border-left:1px solid #FFF;*/
  border-right:1px solid #ddd;
  height:65%;
  display:flex;
  align-items: center;
  justify-content: center;
  font-size:14px;
  letter-spacing: 0.4px;
  font-family: "Open Sans", sans-serif;
  color: rgba(145,145,145,1);
}

.save_div{
  display:flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 1200px) {
  .header{
    min-width:inherit;
    width:100%;
    height:auto;
  }
  .css_header{
    width:100%;
    height:40px;
    /*flex-direction:column;*/
    /*flex-wrap: wrap;*/
    padding:5px 5px;
    align-items: center;
    /*justify-content: center;*/
  }
  .left_header_group {
    /*
    width:100%;
    height:auto;
    align-items: center;
    justify-content: space-around;
    flex-wrap:wrap;
    */
    width:auto;
    height:auto;
    margin-left:5px;
  }
  .center_header_group{
    display:none;
  }
  .right_header_group {
    /*
    width:100%;
    height:auto;
    display:flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap:wrap;
    */
    width:auto;
    flex-grow:1;
  }
  .right_header_group .header_item{
    padding:5px;
  }
  .project_title_box{
    display:none;
  }
  .header_publish{
    display:none;
  }
  .joindesign_logo{
    width:auto;
    height:auto;
    flex:0;
  }
  .logo_img{
    /*width:30px;*/
    height:100%;
  }
  .save_btn{
    margin-left:0;
  }
  .save_status_text{
    width:auto;
  }

  .save_div{
    display:none;
  }
  .file_menu{
    display:none;
  }
  .cp_resize_btn{
    display:none;
  }
}
.cp_resize_btn {
  width: 60px;
}




.lds-dual-ring {
  display: inline-block;
  width: 40px;
  height: 40px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 30px;
  height: 30px;
  margin: 0;
  border-radius: 50%;
  border: 4px solid #333;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 22px;
}
.lds-ellipsis div {
  position: absolute;
  top: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}


.create_team_btn{
  padding:6px 20px;
  background-color:transparent;
  display:inline-block;
  margin-top:5px;
  color:#0f92ba;
  border-radius: 5px;
  cursor:pointer;
  /*letter-spacing: 1px;*/
  font-size:12px;
  /*font-family:BryantMedium !important;*/
  transition: all 0.2s;
  width:125px;
  text-align: center;
  font-weight:bold;
  border:1px solid #0f92ba;
}

.create_team_btn:hover{
  /*
  background-color:#fff;
  color:#0f92ba;
  border:1px solid #0f92ba;
  */
  box-shadow: 5px 5px 5px rgba(0,0,0,0.4);
  /*filter: drop-shadow(5px 5px 2px rgba(0, 0, 0, 0.7));*/
  transform: translate(0px, -3px);
}

.publish_item_title{
  width:50px;
}

.publish_item_desc{
  font-size:12px;
  color:#897ab6;
  margin-left:10px;
}

#shareModal{
  background-color:rgba(255,255,255,0.4);
  border:none;
  display:flex;
  align-items: center;
  justify-content: center;
}

#shareModal .modal-content{
  min-height:inherit;
  position: relative;
  border:none;
  box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
  width:500px;
  padding:30px 30px;
  margin: inherit;
}

.shareConfirmBox{
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.shareConfirmTitle{
  font-family:"Open Sans Regular", "BryantMedium", "Open Sans", sans-serif;
  font-weight:bold;
  font-size:24px;
  color:#555;
}

.shareConfirmDesc{
  margin-bottom:50px;
  font-family:"Open Sans Regular", "BryantMedium", "Open Sans", sans-serif;
  font-size:20px;
  color:#777;
}

.shareConfirmDesc b{
  color:#555;
}

.alertBoxOK{
  padding:5px 60px !important;
}

.social_media_list{
  display:flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width:100%;
  height:auto;
}

.social_media_list .title{
  font-size:23px;
  font-weight:bold;
  font-family:"Open Sans Regular", "BryantMedium", "Open Sans", sans-serif;
  color:#333;
}

.social_media_list .desc{
  margin:10px 0;
  font-family:"Open Sans Light", "BryantMedium", "Open Sans", sans-serif;
  color:#999;
  font-weight:bold;
}

.share_box{
  margin:10px 0;
}

.close{
  display:flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top:0;
  right:10px;
  /*transform: translate(80px, -10px);*/
}

.close_text{
  font-size:14px;
  margin-top:2px;
  margin-left:5px;
  font-family:"Open Sans Light", "BryantMedium", "Open Sans", sans-serif;
}

.at-icon-wrapper{
  transform: scale(0.9);
  border-radius:20px;
  overflow:hidden;
}

@media only screen and (max-width: 767px) {
  .header {
    overflow: hidden;
  }
  .header_item .btn_text {
    display: none;
  }
  .header_item.header_next_btn .btn_text {
    display: flex;
  }
}
