.range_transparency_container {
    margin-left: -200px;
    width: 350px;
    right: -15px;
}
.range_transparency {
    width:85%
}
.range {
    position: relative;
    height: 100%;
    cursor: pointer;
}
.label_range_container{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 15px 20px;
}
.label_range_width {
    width: 150px;
    padding: 5px 10px;
}

.transparent_icon{
    width:30px;
    height:30px;
    cursor:pointer;
}