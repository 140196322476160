$REACT_APP_API_CDN_HOST: 'https://d24vpepiy8g9c6.cloudfront.net';
.product-option-panel {
  background: white;
  width: 200px;
  box-shadow:3px 3px 6px rgba(0,0,0,0.3);
  position: relative;
  padding: 10px 20px;

  .option-container {
    margin: 20px 0;
    .option-title {
      margin: 0;
      line-height: 28px;
      font-size: 18px;
      font-family: BryantMedium;
    }
    .variation {
      display: inline-block;
      border: solid 1px #eeeeee;
      background-color: #eeeeee;
      width: 30px;
      height: 30px;
      margin-right: 10px;
      cursor: pointer;
      &.active {
        background-color: white;
        border: solid 1px #8c6ea8;
        border-radius: 0;
      }
      .text-content {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 100%;
        height: 100%;
        color: black;
        font-family: BryantMedium;
        font-size: 14px;
      }
      &.color {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        position: relative;
        &.active:after {
          content: "";
          display: block;
          top: -3px;
          left: -3px;
          border-radius: 50%;
          border: 3px solid #8c6ea8;
          width: 38px;
          height: 38px;
          position: absolute;
        }
      }
    }
  }

  .variation_img{
    width:30px;
  }

  .close-btn {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 15px;
    top: 15px;
  }
}
