.check_icon {
    width:24px;
    height:24px;
}
.list_box {
    padding: 0;
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    height: 100%;
    line-height: 1.6;
}
.btn {
    background: transparent;
    border: 0;
    border-radius: 0;
    cursor: pointer;
    box-sizing: border-box;
    color: #0e1318;
    outline: none;
    padding: 0;
}
.active {
    background-color: rgba(14,19,24,.07);
    border-radius: 4px;
}
.image {
    width: 35px;
    height: 35px;
}
.btn:hover {
    background-color: rgba(14,19,24,.15);
    border-radius: 4px;
}
