.element_control_panel {
    z-index: 10;
}
.header_group_outline {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    line-height: 1;
}
.header_group_outline > div {
    margin: 0 4px;
}
.font_family_picker, .font_size_picker{
    width:200px;
    margin-left:3px;
    margin-right:3px;
    color:black;
}

.font_style_group {
    display : flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 8px;
}

.font_bold{
    width: 40px;
    font-weight:700;
}
.font_bold_btn {
    outline: none;
    border-color: transparent;
    background-color: transparent;
    padding: 0;
    border-radius: 4px;
    cursor: pointer;
    margin: 3px;

    height: 32px;
    min-width: 0;
    color: #0e1318;
    transition: background-color .3s ease,color .2s ease-out;
}

.font_bold_btn.active {
   background-color: rgba(14,19,24,.15);
}

.font_bold_txt {
    cursor: pointer;
}
.font_italic{
    width: 40px;
    font-style: italic;
}
.font_italic_txt {
    cursor: pointer;
    width:30px;
    height:30px;
}
.font_italic_btn {
    outline: none;
    border-color: transparent;
    background-color: transparent;
    padding: 0;
    border-radius: 4px;
    cursor: pointer;
    margin: 3px;

    height: 32px;
    min-width: 0;
    color: #0e1318;
    transition: background-color .3s ease,color .2s ease-out;
}

.element_control_btn {
    border-color: transparent;
    background-color: rgba(14,19,24,.15);

    border-radius: 4px;
    cursor: pointer;
    margin: 0 5px;

    height: 32px;
    min-width: 0;
    color: #0e1318;
    transition: background-color .3s ease,color .2s ease-out;
}

.font_italic_btn.active {
    background-color: rgba(14,19,24,.15);
}

.align_center_icon {
    width:50px;
    height:50px;
    border-left:1px solid #DDD;
    border-right:1px solid #DDD;
    cursor:pointer;
}
.alignment_panel_container {
    overflow: visible;
}

.show_popup {
    display: block;
}

.toolbar_panel.hide {
    display: none;
}

.color_picker {
    width:50px;
    margin-left: 3px;
    cursor:pointer;
}
.picker_text {
    display: flex;
    justify-content: center;
}
.twitter-picker {
    z-index: 1;
}
.filter_btn{
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    height: 32px;
    margin: 5px;
    padding: 5px;
}
.filter_active {
    background-color: rgba(14,19,24,.07);
}
.filter_btn:focus {
    outline: none;
}
.title_box {
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    margin: 5px;
    padding: 5px;
}
.title_box:hover {
    background-color: rgba(14,19,24,.07);
}
.position_active {

}

.bg_color_picker_btn {
    outline: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    background: transparent;
    transform: translateX(0);
    width: 30px;
    height: 30px;
}
.bg_color_picker_div {
    width: 100%;
    height: 100%;
}
.bg_color_title {
    border-radius: 5px;
    box-shadow: inset 0 0 0 1px rgba(14,19,24,.15);
    position: relative;
    width: 100%;
    height: 100%;
}
.copy_element {
    margin: 10px;
    padding: 5px;
    cursor: pointer;
}
.header_fonts {
    font-family: BryantMedium;
    font-size: 12pt;
}
.header_outline {
    background: #fff;
    box-shadow: 0 1px 0 rgba(14,19,24,.15);
    padding: 8px;
}
.header_outline_style {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 48px;
    outline: none;
}

.read_only_mode{
    background-color:#db9bca;
    padding:5px;
    text-align:center;
    color:#FFF;
}
.font_bold, .font_italic {
   margin-right: 4px;
}
.font_bold button, .font_italic button, .align_picker button, .picker-panel button {
    padding: 0 !important;
    outline: none;
}
.picker-panel button {
    margin: 5px;
}
.copy_icon, .unlock_icon, .adjust_icon{
    width:30px;
    height:30px;
    cursor:pointer;
}
.lock_icon{
	width:30px;
    height:30px;
    cursor:pointer;
    background-color:#EEE;
}
@media only screen and (max-width: 767px) {
    .element_control_panel{
        order:2;
        position: fixed;
        bottom: 70px;
        width: 100%;
    }
    .header_outline_style {
        flex-wrap:wrap;
        height:auto;
        align-items: flex-start;
        justify-content: flex-start;
        padding:0;
    }

    .header_outline_style>div>div{
        /*padding:5px 0px;*/
    }

    .header_group_outline{
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        line-height:0.5;
        width: 100%;
    }
    .header_group_outline>*, .header_group_outline > div {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    /*
    .header_group_outline.left{
        overflow:auto;
        height:350px;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .header_group_outline.left>div{

    }
    */
    .header_group_outline.right{
        /*display:none;*/
    }

    .popup_contianer {
        transform: translate(0px, -530px);
    }
    .popup_contianer_align {
        transform: translate(-100px, -150px);
    }
    .slider_control_container, .toolbar_panel_container{
        /*transform: translate(-250px, -200px);*/
        display:none;
    }
}

