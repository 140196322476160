$REACT_APP_API_CDN_HOST: 'https://d24vpepiy8g9c6.cloudfront.net';
.element {
  border: 1px solid hsla(0,0%,50%,0);
  position: absolute;
  &.print_only {
    border: none;
  }
  .preview_container {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border: 2px solid #14abe7;
  }
}

.element.hide {
  display: none;
}

.element:hover,.element.is_active {
  border: 1px solid hsla(0,0%,50%,.5);
}
.preview_only.element:hover,.preview_only.element.is_active {
  border: 1px solid rgba(0,0,0,0);
}
.instant-upload {
  border: 2px dashed #2dffc5;
}
.instant-upload:before{
  content: "\f030";
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  position: absolute;
  top: -10px;
  left: -10px;
  font-size: 20px;
  z-index: 20;
  color: #2dffc5;
}
.instant-upload.element:hover {
  border: 3px solid #2dffc5;
}
.transform_tool_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.content_element {
  position: relative;
  &.clip {
    overflow: hidden;
  }
  &.overflow {
    overflow: visible!important;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.bg_img_src {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
}
