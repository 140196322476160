.btn_text_style {
     display: -webkit-inline-box;
     letter-spacing: .01rem;
     line-height: 1.4;
     max-width: 100%;
     outline: 0;
     vertical-align: middle;
     -webkit-box-align: center;
     align-items: center;
 }
.btn_text_container {
    height: 32px;
    min-width: 0;
    color: #0e1318;
    transition: background-color .3s ease,color .2s ease-out;
    -webkit-box-pack: center;
    justify-content: center;
}
.btn_outline_container {
    background: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
}
.btn_outline_margin {
    margin: 0 4px;
    padding: 0 3px;
}
.btn_text_style:hover {
    background-color: rgba(14,19,24,.15);
}

.header_border:hover {
    border-radius: 4px;
    background-color: rgba(14,19,24,.15);
}
.active_btn {
    background-color: rgba(14,19,24,.07);
}
.btn_title {
    padding: 0 4px;
    margin:auto;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.btn_title_fonts {
    font-family: BryantMedium;
    font-size: 13pt;
    color: #4d4d4d;
}

@media only screen and (max-width: 1000px) {
    .btn_title_fonts{
        font-size: 10pt;
    }
}

@media only screen and (max-width: 760px) {
    .btn_outline_container {
        border-color: #e4e4e4;
    }
    .header_group_outline > div {
        margin-left: 0;
        margin-right: 0;
    }
    .btn_outline_margin {
        margin-right: 0;
    }
}
