.project_container {
  width : 100%;
  height: 100%;
  position: relative;
}
.second_layer {
  width : 100%;
  height: 100%;
  position: relative;
}
.main {
  height: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  flex-wrap: nowrap;
}

.loading_overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
}

.loading_overlay.hide {
  display: none;
}
.btn_outline {
  max-width: 100%;
  outline: 0;
  vertical-align: middle;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
}
.btn_boader {
  background-color: transparent;
  border: 1px solid transparent;
}
