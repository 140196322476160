.delete_icon{
    width:30px;
    height:30px;
    cursor:pointer;
}
.disable_delete_icon{
	width:30px;
    height:30px;
	pointer-events:none;
	margin-right:6px;
	margin-left:6px;
	opacity: 0.3;
}