$REACT_APP_API_CDN_HOST: 'https://d24vpepiy8g9c6.cloudfront.net';
.slider_control_container {
  position: relative;
}
.slider_container {
  position: absolute;
  margin-left: -75px;
  width: 450px;
  .s_contianer_align {
    box-shadow: 0 0 0 1px rgba(14,19,24,.07), 0 2px 12px rgba(14,19,24,.2);
  }
  .reset_btn {
    cursor: pointer;
  }
}
.spacing_label {
  width: 150px;
}
.spacing_text_val {
  width: 30px;
  border: 1px solid #888888;
  border-radius: 4px;
  padding: 5px;
  text-align: center;
}
